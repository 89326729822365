import { apiConstants } from "../../constants/api.constants";
import ApiService from "./ApiServices";

export default class contentPageServices {

    static getContentPageData = () => {
        return ApiService.post(apiConstants.API_GET_CONTENT_PAGE_LIST);
    };

    static deleteContentPageData = ({ qprm }) => {
        return ApiService.post(apiConstants.API_REMOVE_CONTENT_PAGE_LIST, { qprm: qprm });
    };

    static createContentPage = ({ qprm }) => {
        return ApiService.post(apiConstants.API_CREATE_CONTENT_PAGE, { qprm: qprm });
    };

    static getContentPageDataById = ({ qprm }) => {
        return ApiService.post(apiConstants.API_GET_SINGLE_CONTENT_PAGE, { qprm: qprm });
    };

    static updateContentPage = ({ qprm }) => {
        return ApiService.post(apiConstants.API_UPDATE_CONTENT_PAGE, { qprm: qprm });
    };

}
