import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import contentPageServices from "../components/services/contentPageService";

export const getContentPageData = createAsyncThunk(
    "contentPage/getContentPageData",
    async () => {
        try {
            const response = await contentPageServices.getContentPageData();
            if (typeof response !== "undefined" && typeof response.data !== "undefined") {
                const data = await response.data;
                return data;
            }
        } catch (error) {
            return error.response.data;
        }
    }
);

export const deleteContentPageData = createAsyncThunk(
    "contentPage/deleteContentPageData",
    async ({ qprm }) => {
        try {
            const response = await contentPageServices.deleteContentPageData({ qprm });
            if (typeof response !== "undefined" && typeof response.data !== "undefined") {
                const data = await response.data;
                return data;
            }
        } catch (error) {
            return error.response.data;
        }
    }
);

export const getContentPageDataById = createAsyncThunk(
    "contentPage/getContentPageDataById",
    async ({ qprm }) => {
        try {
            const response = await contentPageServices.getContentPageDataById({ qprm });
            if (typeof response !== "undefined" && typeof response.data !== "undefined") {
                const data = await response.data;
                return data;
            }
        } catch (error) {
            return error.response.data;
        }
    }
);

export const createContentPage = createAsyncThunk(
    "contentPage/createContentPage",
    async ({ qprm }) => {
        try {
            const response = await contentPageServices.createContentPage({ qprm });
            if (typeof response !== "undefined" && typeof response.data !== "undefined") {
                const data = await response.data;
                return data;
            }
        } catch (error) {
            return error.response.data;
        }
    }
);

export const updateContentPage = createAsyncThunk(
    "contentPage/updateContentPage",
    async ({ qprm }) => {
        try {
            const response = await contentPageServices.updateContentPage({ qprm });
            if (typeof response !== "undefined" && typeof response.data !== "undefined") {
                const data = await response.data;
                return data;
            }
        } catch (error) {
            return error.response.data;
        }
    }
);
export const contentPageSlice = createSlice({
    name: "contentPageSlice",
    initialState: {
        loading: false,
        error: null,
        data: [],
    },
    reducers: {},
    extraReducers: {
        [getContentPageData.pending]: (state) => {
            state.loading = true;
        },
        [getContentPageData.rejected]: (state, action) => {
            state.loading = false;
        },
        [getContentPageData.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [deleteContentPageData.pending]: (state) => {
            state.loading = true;
        },
        [deleteContentPageData.rejected]: (state, action) => {
            state.loading = false;
        },
        [deleteContentPageData.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [getContentPageDataById.pending]: (state) => {
            state.loading = true;
        },
        [getContentPageDataById.rejected]: (state, action) => {
            state.loading = false;
        },
        [getContentPageDataById.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [createContentPage.pending]: (state) => {
            state.loading = true;
        },
        [createContentPage.rejected]: (state, action) => {
            state.loading = false;
        },
        [createContentPage.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [updateContentPage.pending]: (state) => {
            state.loading = true;
        },
        [updateContentPage.rejected]: (state, action) => {
            state.loading = false;
        },
        [updateContentPage.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },

    },
});

export default contentPageSlice.reducer;

