import React, { PureComponent } from "react";
import axios from "axios";
import { withCookies } from 'react-cookie';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { setVIInfo, capitalizeFirstLetter, encryptIt, decryptIt, getJwtToken, loading, encryptVal, getDomainId, getDomainInfo } from './components/common/commonHelper';
import { apiConstants } from "./constants/api.constants";
import { USE_DEFAULT_DOMAIN, VI_IMAGES_URL } from "./constants/commonconstants";
import i18n from "./i18n";
import SkeletonLoader from "./components/front/SkeletonLoader";
import SaveTableAndRedirect from "./components/front/SaveTableAndRedirect";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const baseUrl = i18n.language === 'undefined' ? 'en' : '/' + i18n.language;

const MainLayout = React.lazy(() => import('./components/front/MainLayout'));
const ErrorPage = React.lazy(() => import('./components/common/ErrorPage'));
class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      got_vi_setting: false,
      vi_setting: false,
      langObj: '',
      got_lang_setting: false,
      lang_options: [],
    };
    this.setVIsettings = this.setVIsettings.bind(this);
  }

  async setVIsettings(viSettings) {
    if (typeof viSettings !== 'undefined' && viSettings !== null && viSettings !== '') {
      await setVIInfo(viSettings);
      if (typeof viSettings[4] !== 'undefined' && viSettings[4] !== null && viSettings[4] !== '') {
        let vset = decryptIt(viSettings[4]).slice(1, -1);
        let parsedSettings = JSON.parse(vset);
        this.setState({ got_vi_setting: true, vi_setting: parsedSettings });
      }
      if (typeof viSettings[1] !== 'undefined' && viSettings[1] !== null && viSettings[1] !== '') {
        document.body.classList.add("domain-" + decryptIt(viSettings[1]).slice(1, -1).replaceAll("_", "-"));
      }
      if (typeof viSettings[2] !== 'undefined' && viSettings[2] !== null && viSettings[2] !== '') {
        document.title = document.querySelector("meta[name='apple-mobile-web-app-title']").content = document.querySelector("meta[name='application-name']").content = decryptIt(viSettings[2]).slice(1, -1);
        localStorage.setItem("Home Page Title", decryptIt(viSettings[2]).slice(1, -1));
      }
      if (typeof viSettings[3] !== 'undefined' && viSettings[3] !== null && viSettings[3] !== '') {
        document.body.setAttribute('data-sitedist', decryptIt(viSettings[3]).slice(1, -1).toLowerCase());
      }
    }
  }

  async UNSAFE_componentWillMount() {
    await this.getVIsettings();
  }

  async getVIsettings() {
    if (navigator.cookieEnabled) {
      var apiUrl = apiConstants.API_DOMAIN_INFO;
      console.log(window.location.hostname);
      console.log(window.location.protocol.replace(":", ""));
      let domainHost = ((typeof window.location.hostname !== 'undefined' && !USE_DEFAULT_DOMAIN) ? window.location.hostname : 'mp-03-live.mediapad.cloud');
      let domainPort = ((typeof window.location.protocol !== 'undefined' && !USE_DEFAULT_DOMAIN) ? window.location.protocol.replace(":", "") : 'https');
      var qdhp = encryptIt(domainHost + '~~' + domainPort);
      await axios({
        method: "post",
        url: apiUrl,
        data: { qdhp: qdhp }
      }).then(async response => {
        if (response.data.status === 1 && typeof response.data.data !== 'undefined' && response.data.data !== null && response.data.data !== '') {
          let decryptedDt = decryptIt(response.data.data);
          decryptedDt = (decryptedDt) ? decryptedDt.slice(1, -1) : '';
          let dtObj = decryptedDt.split("!#!");
          let dinfo = localStorage.getItem(encryptVal('dinfo'));
          if (typeof dinfo != 'undefined' && dinfo != null && dinfo != "" && (document.cookie.indexOf(encryptVal("geo_verified") + '=') != -1)) {
            let parseddInfo = JSON.parse(dinfo);
            if (typeof parseddInfo[5] == 'undefined' && typeof dtObj[5] != "undefined" && dtObj[5] != null && dtObj[5] != "") {
              parseddInfo[5] = dtObj[5];
            }
            if (typeof parseddInfo[8] == 'undefined' && typeof dtObj[8] != "undefined" && dtObj[8] != null && dtObj[8] != "") {
              parseddInfo[8] = dtObj[8];
            }
            dtObj = parseddInfo;
          }
          this.setVIsettings(dtObj);
        } else {
          this.setState({
            error: response.data.message
          });
        }
      }).catch(error => this.setState({ error: error.message }));

      await axios({
        method: "post",
        url: apiConstants.API_POST_LANG_INFO,
        headers: { 'Authorization': getJwtToken() }
      }).then(async response => {
        if (typeof response.data !== 'undefined' && response.data.status === 1) {
          let decryptedDt = decryptIt(response.data.data);
          decryptedDt = (decryptedDt) ? decryptedDt.slice(1, -1) : '';
          decryptedDt = JSON.parse(decryptedDt);
          if (decryptedDt.length) {
            let langObj = decryptedDt;
            let lang_arr = [];
            let languages = '/:locale(';
            let totalCount = langObj.length;
            langObj.forEach((value, index) => {
              languages += value.langCode.toLowerCase() + ((index < totalCount - 1) ? '|' : '');
              var obj = {
                label: value.langTitle,
                native: value.langNativeName,
                value: value.langCode.toLowerCase(),
                id: value.uid
              };
              lang_arr.push(obj);
            });
            languages += ")?";
            this.setState({
              'langObj': languages,
              lang_options: lang_arr,
              got_lang_setting: true
            });
          }
        }
      }).catch(error => error.message);
      let vi_settings = this.state.vi_setting;
      let { cookies } = this.props;
      if (typeof vi_settings !== 'undefined' && vi_settings !== null && vi_settings !== '') {
        let myIp = getDomainInfo('userIp');
        let did = getDomainId();
        let isAnalyticCookieExists = (typeof cookies.get('_unvid') !== 'undefined') ? true : false;
        if (!isAnalyticCookieExists) {
          let apiUrl = apiConstants.API_TRACK_VISITOR;
          let params = {
            qdmn: encryptIt(did),
            deviceIp: myIp
          };
          await axios({
            method: "post",
            url: apiUrl,
            headers: { 'Authorization': getJwtToken() },
            data: { qprm: encryptIt(JSON.stringify(params, null, 2)) }
          }).then(async response => {
            if (response.data.status === 1 && typeof response.data.data !== 'undefined' && response.data.data !== null && response.data.data !== '') {
              let decryptedDt = decryptIt(response.data.data);
              decryptedDt = (decryptedDt) ? decryptedDt.slice(1, -1) : '';
              decryptedDt = JSON.parse(decryptedDt);
              if (decryptedDt) {
                //cookies.set('_unvid', encryptVal(decryptedDt), { path: '/', sameSite: 'lax' });
                cookies.set('_unvid', encryptVal(decryptedDt), { path: '/', sameSite: 'None', secure: true });
              }
            }
          });
        }
      }
    } else {
      this.setState({ error: 'Please enable cookies to access your publications.' });
    }
  };

  render() {
    var { got_vi_setting, got_lang_setting, error, vi_setting, langObj, lang_options } = this.state;
    let { metaTag_prerender_header, metaTag_ogurl, metaTag_url, metaTag_image, metaTag_seo_index, metaTag_seo_follow } = vi_setting
    if (typeof metaTag_prerender_header != "undefined" && metaTag_prerender_header != "") {
      let metaElement = document.querySelector(`[name="prerender-header"]`);
      if (metaElement != null && metaElement) metaElement.setAttribute("content", `Location: ${metaTag_prerender_header}`)
      else {
        let newElem = document.createElement("meta")
        newElem.setAttribute("name", "prerender-header")
        newElem.content = `Location: ${metaTag_prerender_header}`
        document.head.appendChild(newElem)
      }
    }
    if (typeof metaTag_url != "undefined" && metaTag_url != "") {
      let metaElement = document.querySelector(`[property="url"]`);
      let twitElement = document.querySelector(`[name="twitter:url"]`);
      if (metaElement != null && metaElement) metaElement.setAttribute("content", metaTag_url)
      else {
        let newElem = document.createElement("meta")
        newElem.setAttribute("property", "url")
        newElem.content = metaTag_url
        document.head.appendChild(newElem)
      }
      if (twitElement != null && twitElement) twitElement.setAttribute("content", metaTag_url)
      else {
        let newElem = document.createElement("meta")
        newElem.setAttribute("name", "twitter:url")
        newElem.content = metaTag_url
        document.head.appendChild(newElem)
      }
    }
    if (typeof metaTag_ogurl != "undefined" && metaTag_ogurl != "") {
      let metaElement = document.querySelector(`[property="og:url"]`);
      if (metaElement != null && metaElement) metaElement.setAttribute("content", metaTag_ogurl)
      else {
        let newElem = document.createElement("meta")
        newElem.setAttribute("property", "og:url")
        newElem.content = metaTag_ogurl
        document.head.appendChild(newElem)
      }
    }
    if (typeof metaTag_image != "undefined" && metaTag_image != "") {
      let metaElement = document.querySelector(`[property="og:image"]`);
      if (metaElement != null && metaElement) metaElement.setAttribute("content", metaTag_image)
      else {
        let newElem = document.createElement("meta")
        newElem.setAttribute("property", "og:image")
        newElem.content = metaTag_image
        document.head.appendChild(newElem)
      }
      let twitElement = document.querySelector(`[name="twitter:image"]`);
      if (twitElement != null && twitElement) twitElement.setAttribute("content", metaTag_image)
      else {
        let newElem = document.createElement("meta")
        newElem.setAttribute("name", "twitter:image")
        newElem.content = metaTag_image
        document.head.appendChild(newElem)
      }

    }

    const metaTags = document.querySelectorAll('meta[name="robots"]');

    let contentValue = 'noarchive';

    if (metaTag_seo_index && typeof metaTag_seo_index !== 'undefined' && metaTag_seo_index == 1) {
      contentValue += ', index';
    } else {
      contentValue += ', noindex';
    }

    if (metaTag_seo_follow && typeof metaTag_seo_follow !== 'undefined' && metaTag_seo_follow == 1) {
      contentValue += ', follow';
    } else {
      contentValue += ', nofollow';
    }

    metaTags.forEach(metaTag => {
      metaTag.setAttribute('content', contentValue);
    });

    return (
      <>
        <Router>
          <Switch>
            {vi_setting !== false && <Route path={["/table/:tableNo", "/room/:tableNo"]} component={(props) => (<SaveTableAndRedirect {...this.props} {...props} vi_settings={vi_setting} />)} />}
          </Switch>
        </Router>
        {(got_vi_setting && got_lang_setting) ? <React.Suspense fallback={<></>}><MainLayout vi_settings={vi_setting} key={(vi_setting) ? JSON.stringify(vi_setting) : ""} langObj={langObj} lang_options={lang_options} setVIsettings={this.setVIsettings} /><ToastContainer /></React.Suspense> : ((error !== '') ? <ErrorPage message={error} /> : null)}
      </>
    );
  }
}

export default withCookies(App);
