import { POOL_DATA } from '../../constants/commonconstants';
const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

export default class AuthenticatedOperations {
  constructor({ username, idToken, accessToken, refreshToken }) {
    this.username = username;
    this.pool = new AmazonCognitoIdentity.CognitoUserPool(POOL_DATA);
    this.user = new AmazonCognitoIdentity.CognitoUser(
      { Username: username, Pool: this.pool }
    );
    this.session = new AmazonCognitoIdentity.CognitoUserSession({
      IdToken: new AmazonCognitoIdentity.CognitoIdToken({ IdToken: idToken }),
      AccessToken: new AmazonCognitoIdentity.CognitoAccessToken({ AccessToken: accessToken }),
      RefreshToken: new AmazonCognitoIdentity.CognitoRefreshToken({ RefreshToken: refreshToken })
    });
  }

  signOut() {
    this.user.setSignInUserSession(this.session);
    return new Promise((resolve, reject) => {
      this.user.globalSignOut({
        onSuccess: resolve,
        onFailure: reject
      });
    });
  }

async changePassword({ currentPassword, newPassword }) {
    this.user.setSignInUserSession(this.session);
    return new Promise((resolve, reject) => {
      this.user.changePassword(currentPassword, newPassword, (err, result) => {
        if (err) {
          return reject(err);
        }
        return resolve(result);
      });
    });
  }
}