import React from "react";
import { withCookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';
import { getMixPanel } from "../common/commonHelper";

const SaveTableAndRedirect = (props) => {
    let {cookies,vi_settings} = props;
    if(vi_settings?.enable_mix_panel_tracking || 0 && typeof vi_settings.mix_panel_api_key !== "undefined" && vi_settings.mix_panel_api_key !== ""){
        getMixPanel().track(`Table number ${props.match.params.tableNo} event tracked.`);
    }
    cookies.set("table_no", props.match.params.tableNo, {path: "/",sameSite: "lax",maxAge:24*60*60});
    return <Redirect push to={"/"} />
}

export default withCookies(SaveTableAndRedirect);