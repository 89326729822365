import { apiConstants } from "../../constants/api.constants";
import ApiService from "./ApiServices";

const add = `/`;

export default class adminUserServices {
  static adminUserLogin = ({ qprm }) => {
    return ApiService.post(apiConstants.ADMIN_USER_LOGIN, { qprm: qprm });
  };

  static adminForgetPassword = ({ qprm }) => {
    return ApiService.post(apiConstants.API_FORGOT_PASSWORD, { qprm: qprm });
  };

  static adminResetPassword = ({ qprm }) => {
    return ApiService.post(apiConstants.API_RESET_PASSWORD, { qprm: qprm });
  };

  static adminUserList = () => {
    return ApiService.get(apiConstants.API_GET_ALL_USER, {});
  };

  static adminGetAllRolesList = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_ROLE_LIST, { qprm: qprm });
  };

  static adminCreateRole = ({ qprm }) => {
    return ApiService.post(apiConstants.API_CREATE_ROLE, { qprm: qprm });
  };

  static adminUpdateRole = ({ qprm }) => {
    return ApiService.post(apiConstants.API_UPDATE_ROLE, { qprm: qprm });
  };

  static adminGetRoleInfoFromUid = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_ROLE_LIST_FROM_UID, {
      qprm: qprm,
    });
  };

  static adminRemoveRole = ({ qprm }) => {
    return ApiService.post(apiConstants.API_REMOVE_ROLE_INFO, { qprm: qprm });
  };

  static adminCreateUser = ({ qprm }) => {
    return ApiService.post(apiConstants.API_CREATE_USER, { qprm: qprm });
  };

  static adminUpdateUser = ({ qprm }) => {
    return ApiService.post(apiConstants.API_UPDATE_USER, { qprm: qprm });
  };

  static adminGetUserInfoFromUid = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_USER_LIST_FROM_UID, { qprm });
  };

  static adminRemoveUser = ({ qprm }) => {
    return ApiService.post(apiConstants.API_REMOVE_USER_INFO, { qprm: qprm });
  };

  static adminCheckUserEmail = ({ qprm }) => {
    return ApiService.post(apiConstants.CHECK_USER_EMAIL, { qprm: qprm });
  };

  static adminDomainAndMContentList = () => {
    return ApiService.post(apiConstants.API_GET_DOMAIN_AND_MCONTENT_LIST, {});
  };

  static getDomainList = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_DOMAIN_LIST, { qprm: qprm });
  };

  static authDataRequest = ({ qprm }) => {
    return ApiService.post(apiConstants.AUTH_DATA, { qprm: qprm });
  };

  static adminGetUniqueUserInfo = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_UNIQUE_USER_INFO, { qprm: qprm });
  }

  static adminGetPermissionList = () => {
    return ApiService.post(apiConstants.API_GET_PERMISSION_LIST);
  }

}
