import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { decryptIt } from '../components/common/commonHelper';
import adminLangServices from '../components/services/adminLangServices';


export const adminUidLang = createAsyncThunk('adminLangInfo/adminUidLangInfo', async ({ qprm }) => {
    try {

        const response = await adminLangServices.adminUidLangInfo({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const adminRemoveLang = createAsyncThunk('adminLangInfo/adminRemoveLangInfo', async ({ qprm }) => {
    try {
        const response = await adminLangServices.adminRemoveLangInfo({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const adminLangDefaultUpdate = createAsyncThunk('adminLangInfo/adminLangDefaultUpdate', async ({ qprm }) => {
    try {
        const response = await adminLangServices.adminLangDefaultUpdate({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const adminCreateLang = createAsyncThunk('adminDomainInfo/adminCreateLang', async ({ qprm }) => {
    try {
        const response = await adminLangServices.adminCreateLangInfo({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const adminUpdateLang = createAsyncThunk('adminDomainInfo/adminUpdateLang', async ({ qprm }) => {
    try {
        const response = await adminLangServices.adminUpdateLangInfo({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);


export const adminLangStatusUpdate = createAsyncThunk('adminDomainInfo/adminLangStatusUpdate', async ({ qprm }) => {
    try {

        const response = await adminLangServices.adminLangStatusUpdate({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const adminDaomainTransText = createAsyncThunk('adminDomainInfo/adminDaomainTransText', async ({ qprm }) => {
    try {

        const response = await adminLangServices.adminDaomainTransText({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const adminUpdateTransText = createAsyncThunk('adminDomainInfo/adminUpdateTransText', async ({ qprm }) => {
    try {

        const response = await adminLangServices.adminUpdateTransText({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const adminGetAdminLangList = createAsyncThunk('adminDomainInfo/adminGetAdminLangList', async () => {
    try {

        const response = await adminLangServices.adminGetLangList();
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const adminCreateLangInfoReact = createAsyncThunk('adminDomainInfo/adminCreateLangInfoReact', async ({ qprm }) => {
    try {
        const response = await adminLangServices.adminCreateLangInfoReact({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const adminGetTextLangs = createAsyncThunk('adminDomainInfo/adminGetTextLangs', async () => {
    try {
        const response = await adminLangServices.adminGetTextLangs();
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const adminGetSpecificLangText = createAsyncThunk('adminDomainInfo/adminGetSpecificLangText', async ({ qprm }) => {
    try {
        const response = await adminLangServices.adminGetSpecificLangText({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const adminCreateLangText = createAsyncThunk('adminDomainInfo/adminCreateLangText', async ({ qprm }) => {
    try {
        const response = await adminLangServices.adminCreateLangText({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const adminDeleteLangText = createAsyncThunk('adminDomainInfo/adminDeleteLangText', async ({ qprm }) => {
    try {
        const response = await adminLangServices.adminDeleteLangText({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);



export const adminLangSlice = createSlice({
    name: 'adminLangInfo',
    initialState: {
        loading: false,
        error: null,
        data: [],
        submitLang:false,
        submitLangText:false
    },
    reducers: {},
    extraReducers: {
        [adminUidLang.pending]: (state) => {
            state.loading = true;
        },
        [adminUidLang.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminUidLang.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminRemoveLang.pending]: (state) => {
            state.loading = true;
        },
        [adminRemoveLang.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminRemoveLang.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminLangDefaultUpdate.pending]: (state) => {
            state.loading = true;
        },
        [adminLangDefaultUpdate.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminLangDefaultUpdate.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },

        [adminCreateLang.pending]: (state) => {
            state.loading = true;
        },
        [adminCreateLang.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminCreateLang.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminUpdateLang.pending]: (state) => {
            state.submitLang = true;
        },
        [adminUpdateLang.rejected]: (state, action) => {
            state.submitLang = false;
        },
        [adminUpdateLang.fulfilled]: (state, action) => {
            state.submitLang = false;
            state.data = action.payload.data;
        },
        [adminLangStatusUpdate.pending]: (state) => {
            state.loading = true;
        },
        [adminLangStatusUpdate.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminLangStatusUpdate.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminDaomainTransText.pending]: (state) => {
            state.loading = true;
        },
        [adminDaomainTransText.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminDaomainTransText.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminUpdateTransText.pending]: (state) => {
            state.loading = true;
        },
        [adminUpdateTransText.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminUpdateTransText.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminGetAdminLangList.pending]: (state) => {
            state.loading = true;
        },
        [adminGetAdminLangList.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminGetAdminLangList.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload?.data) {
                let decryptedDt = decryptIt(action.payload.data);
                decryptedDt = (decryptedDt) ? decryptedDt.slice(1, -1) : '';
                decryptedDt = JSON.parse(decryptedDt);
                state.data = decryptedDt;
            }
        },
        [adminCreateLangInfoReact.pending]: (state) => {
            state.submitLang = true;
        },
        [adminCreateLangInfoReact.rejected]: (state, action) => {
            state.submitLang = false;
        },
        [adminCreateLangInfoReact.fulfilled]: (state, action) => {
            state.submitLang = false;
            state.data = action.payload.data;
        },
        [adminGetTextLangs.pending]: (state) => {
            state.loading = true;
        },
        [adminGetTextLangs.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminGetTextLangs.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminGetSpecificLangText.pending]: (state) => {
            state.loading = true;
        },
        [adminGetSpecificLangText.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminGetSpecificLangText.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminCreateLangText.pending]: (state) => {
            state.submitLangText = true;
        },
        [adminCreateLangText.rejected]: (state, action) => {
            state.submitLangText = false;
        },
        [adminCreateLangText.fulfilled]: (state, action) => {
            state.submitLangText = false;
            state.data = action.payload.data;
        },
        [adminDeleteLangText.pending]: (state) => {
            state.loading = true;
        },
        [adminDeleteLangText.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminDeleteLangText.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
    }
})


export default adminLangSlice.reducer;
