import * as actions from "../actions/actionTypes";
const reducer = (state = [], action) => {
    switch (action.type){
        case actions.VIINFO:
            return {
                ...state,
                domainVIsetting: action.payload.domainVIsetting,
                jwtToken: action.payload.jwtToken,
                did: action.payload.did,
                domainName: action.payload.domainName,
                domainMachineName: action.payload.domainMachineName,
                domainDistributor: action.payload.domainDistributor,
                userIp: action.payload.userIp,
                userCountry: action.payload.userCountry,
            };
        case 'paymentInfo':
            return{
                ...state,
                url: action.payload.url,
                token: action.payload.token,
                isAccessibleWithoutOtp: action.payload.token.isAccessibleWithoutOtp,          
            };
        case 'tokenAuthInfo':
            return {
                ...state,
                contentUrls: action.payload.token.contentUrls,
                isAccessibleWithoutOtp: action.payload.token.isAccessibleWithoutOtp,
                mediaContentUrl: action.payload.token.mediaContentUrl,
            };          
        default:
            return state;
    }
}
export default reducer;
