import { apiConstants } from "../../constants/api.constants";
import ApiService from "./ApiServices";

export default class adminGeoDomainService {
    static adminGetGeoDomainContentList = () => {
        return ApiService.post(apiConstants.API_GET_GEO_DOMAIN_LIST, {})
    }

    static adminCreateGeoDomain = ({ qprm }) => {
        return ApiService.post(apiConstants.API_CREATE_GEO_DOMAIN, { qprm: qprm })
    }

    static adminGeoDomainInfo = ({ qprm }) => {
        return ApiService.post(apiConstants.API_GET_GEO_DOMAIN_INFO, { qprm: qprm })
    }

    static adminDeleteGeoDomain = ({ qprm }) => {
        return ApiService.post(apiConstants.API_DELETE_GEO_DOMAIN, { qprm: qprm })
    }

    static adminGetGeoDomainMediaContent = ({ qprm }) => {
        return ApiService.post(apiConstants.API_GET_DOMAIN_MEDIA_CONTENT, { qprm: qprm })
    }

}
