import { API_URL } from "./commonconstants";
const PAYMENT = `payment`;
export const apiConstants = {
  API_DOMAIN_INFO: API_URL + "api/verify-dm-info",
  API_GET_LANG_INFO: API_URL + "api/get-dm-lang-info",
  API_POST_LANG_INFO: API_URL + "api/post-dm-lang-info",
  API_POST_LANG_LIST: API_URL + "api/get-dm-lang-list",
  API_GET_DOMAIN_BLOCKS: API_URL + "api/get-dm-blocks",
  API_GET_DOMAIN_CONTENT: API_URL + "api/get-dm-content-list",
  API_TRACK_VISITOR: API_URL + "api/get-dm-unvt-info",
  API_GET_MEDIA_CONTENT_INFO: API_URL + "api/get-dm-mcontent-info",
  API_GET_CONTENT_PAGE_INFO: API_URL + "api/get-dm-content-page-info",
  API_GET_MEDIA_CATEGORIES: API_URL + "api/get-dm-media-categories",
  API_GET_DOMAIN_CONTENT_PAGES: API_URL + "api/get-dm-content-pages",
  API_GET_DOMAIN_MENU_ITEMS: API_URL + "api/get-dm-menu-items",
  API_REPORT_EVENT: API_URL + "api/report-event",
  API_GET_AUDITOR_REPORT: API_URL + "api/get-auditor-analytic-records",
  API_GET_DOMAIN_AND_MCONTENT_LIST: API_URL + "api/get-dm-mediacontent-list",
  API_GET_TRANSLATED_TEXT: API_URL + "api/get-all-translated-text",
  API_GET_MP3_AUDITOR_REPORT: API_URL + "api/get-mp3-auditor-analytic-records",
  API_DOMAIN_PASS_AUTH: API_URL + "api/verify-dm-auth",
  API_GET_DOMAIN_TRANS_TEXT: API_URL + "api/get-dm-trans-text",
  API_UPDATE_TRANS_TEXT: API_URL + "api/update-dm-trans-text",
  API_CREATE_LANG_INFO: API_URL + "api/create-lang-info",
  API_UPDATE_LANG_INFO: API_URL + "api/update-lang-info",
  API_GET_UID_LANG_INFO: API_URL + "api/get-dm-uid-lang-info",
  API_REMOVE_LANG_INFO: API_URL + "api/remove-dm-lang-info",
  API_UPDATE_DEFAULT_LANG_INFO: API_URL + "api/update-default-lang",
  API_UPDATE_LANG_STATUS: API_URL + "api/update-lang-status",
  API_VALIDATE_USER: API_URL + "api/validate-user",
  API_FORGOT_PASSWORD: API_URL + "api/forgot-password",
  API_RESET_PASSWORD: API_URL + "api/reset-password",
  API_GET_DOMAIN_LIST: API_URL + "api/get-dm-list",
  API_GET_ROLE_LIST: API_URL + "api/get-role-list",
  API_GET_DOMAIN_DISTRIBUTOR_INFO: API_URL + "api/get-dm-distributor-info",
  API_CREATE_DOMAIN_INFO: API_URL + "api/dm-create-domain",
  API_GET_UID_DOMAIN_INFO: API_URL + "api/dm-get-domain-info",
  API_REMOVE_DOMAIN_INFO: API_URL + "api/remove-domain-info",
  API_GET_DISTRIBUTOR_LIST: API_URL + "api/get-distibutor-list",
  API_CREATE_DISTRIBUTOR_INFO: API_URL + "api/dm-create-distributor-info",
  API_GET_DISTRIBUTOR_LIST_FROM_UID:
    API_URL + "api/get-distributor-info-from-uid",
  API_REMOVE_DISTRIBUTOR_INFO: API_URL + "api/remove-distributor-info",
  API_GET_MEDIA_CATEGORY_INFO: API_URL + "api/get-media-category-listing",
  API_CREATE_MEDIA_CATEGORY_INFO: API_URL + "api/add-media-category-info",
  API_GET_MEDIA_CATEGORY_LIST_FROM_UID:
    API_URL + "api/get-media-category-info-from-uid",
  API_REMOVE_MEDIA_CATEGORY: API_URL + "api/remove-media-category-info",
  API_GET_MEDIA_CONTENT_LIST: API_URL + "api/get-media-content-list",
  API_GET_MEDIA_CONTENT_LIST_REACT: `${API_URL}api/get-content-list`,
  API_CREATE_MEDIA_CONTENT: API_URL + "api/add-media-content-info",
  API_GET_MEDIA_CONTENT_LIST_FROM_UID:
    API_URL + "api/get-media-content-info-from-uid",
  API_REMOVE_MEDIA_CONTENT: API_URL + "api/remove-content-media-info",
  API_GET_USER_LIST: API_URL + "api/get-users-list",
  API_GET_ALL_USER: `${API_URL}api/get-all-users`,
  API_CREATE_ROLE: API_URL + "api/create-role",
  API_GET_ROLE_LIST_FROM_UID: API_URL + "api/get-role-from-uid",
  API_UPDATE_ROLE: API_URL + "api/update-role",
  API_REMOVE_ROLE_INFO: API_URL + "api/remove-role-info",
  API_CREATE_USER: API_URL + "api/create-user",
  API_UPDATE_USER: API_URL + "api/update-user",
  API_GET_USER_LIST_FROM_UID: API_URL + "api/get-user-from-uid",
  API_REMOVE_USER_INFO: API_URL + "api/remove-user-info",
  CHECK_USER_EMAIL: API_URL + "api/check-user-email-exists",
  DEBITCARD_PAYMENT: `${API_URL}api/${PAYMENT}/payment-using-debitcard`,
  GENERATE_SINGED_URL: `${API_URL}api/${PAYMENT}/generate-signed-url`,
  AUTH_SINGLE_USE_TOKEN: `${API_URL}api/${PAYMENT}/authorize-accessToken`,
  GET_OTP: `${API_URL}api/${PAYMENT}/verify-orders`,
  DISTANCE_CALCULATE: API_URL + "api/distance-calculate",
  GET_STRUCT_JSON_DATA: API_URL + "api/get-structured-json-details",
  CREATE_CLIENT_SECRET: `${API_URL}api/${PAYMENT}/create-payment-intent`,
  UPDATE_CLIENT_SECRET: `${API_URL}api/${PAYMENT}/update-payment-intent`,
  API_SEND_MAGIC_LINK: `${API_URL}api/${PAYMENT}/send-magic-link-to-mail`,
  UPDATE_VISUALSETTINGS_AMENITYS: API_URL + "api/update-visualSettings-amenity",
  API_GET_PAYMENT_SETTINGS: `${API_URL}api/get-content-payment-settings`,
  API_STORE_DATA_FOR_USER_MEDIA_CONTENT: `${API_URL}api/payment/save-special-media-items-user-details`,
  APPLY_COUPON_CODE_AND_GET_VALIDATE_RESULT: `${API_URL}api/payment/apply-promo-code-to-media-content`,
  API_MANAGE_BLOCK_ORDER: `${API_URL}api/manage-domain-block-order`,
  ADMIN_USER_LOGIN: `${API_URL}api/admin-user-login`,
  GET_MASTER_SITE: `${API_URL}api/get-master-site`,
  CREATE_NEW_SITE: `${API_URL}api/create-site`,
  API_GET_DOMAIN_MEDIA_CONTENT_INFO: `${API_URL}api/get-specific-domain-media-content`,
  API_UPDATE_CUSTOM_PUBLICATION_CONTENT_PRICE: `${API_URL}api/update-custom-publication-cost-of-specific-domain`,
  APPLY_COUPON_CODE_AND_GET_VALIDATE_RESULT: `${API_URL}api/apply-promo-code-to-media-content`,
  API_GET_COUPON_CODE_STATUS: `${API_URL}api/get-coupon-code-status`,
  API_GET_COUPON_CODE_LIST: `${API_URL}api/get-all-discount-coupon-codes`,
  API_CREATE_COUPON_CODE: `${API_URL}api/create-discount-coupon-code`,
  API_GET_DISCOUNT_COUPON_CODE: `${API_URL}api/get-one-discount-coupon-code`,
  API_CHECK_UNIQUENESS_COUPON_CODE: `${API_URL}api/check-uniquenes-of-coupon-code`,
  API_DELETE_DISCOUNT_COUPON_CODE: `${API_URL}api/delete-one-discount-coupon-code`,
  API_GET_USER_MEDIA_CONTENT_ACCESS_LIST: `${API_URL}api/payment/generate-csv-report-for-user-media-content-access`,
  API_GET_GROUPED_MEDIA_CONTENT_TYPES: `${API_URL}api/get-all-grouped-media-content-types`,
  API_GET_DOMAIN_CIDS_BASED_ON_TYPE: `${API_URL}api/get-domain-media-content-cids-based-on-type`,
  ASSIGN_CID_TO_DOMAIN_FOR_SPECIFIC_TYPE: `${API_URL}api/assign-manual-domain-media-content`,
  API_GET_CID_WITH_SEARCH_TITLE_DISTRIBUTOR: `${API_URL}api/get-all-media-list-with-title-and-distributor`,
  API_TO_LIST_GEO_LOCATION_CONTENT_PACK_LIST: `${API_URL}api/get-all-geo-domain-content-pack-list`,
  API_TO_GET_SPECIFIC_EXISTSING_CONTENT_PACK_INFO: `${API_URL}api/get-one-geo-content-pack-info`,
  API_TO_SAVE_HOTEL_FEEDBACK_INFO: `${API_URL}api/save-hotels-feedback-info`,
  GET_SPECIFIC_DOMAIN_CONTENT_BLOCKS: `${API_URL}api/get-specific-domain-content-blocks`,
  UPDATE_DOMAIN_BLOCKS_ORDER: `${API_URL}api/update-domainblocks-order`,
  API_TO_GET_ONE_DOMAIN_COMPENDIUM: `${API_URL}api/get-one-domain-compendium`,
  API_TO_GET_MEDIA_LINK_BY_MID: `${API_URL}api/get-media-settings-by-mid`,
  API_TO_LIST_CONTENT_PACK_LIST: `${API_URL}api/get-content-packs-list`,
  API_TO_SAVE_AND_ASSIGN_GEO_DOMAIN_CONTENTS: `${API_URL}api/assign-geo-domain-contents`,
  API_TO_SAVE_AND_ASSIGN_CONTENT_PACK_ITEMS: `${API_URL}api/assign-content-pack-items`,
  API_TO_UPDATE_VISUAL_DATA_FOR_FILE_TYPE_AND_CONTENT_AUTH: `${API_URL}api/update-visual-data-for-file-type-and-content-auth`,
  API_TO_GET_VI_DATA: `${API_URL}api/get-visual-data-detail`,
  API_GET_SPECIFIC_CONTENT_PACK_INFO: `${API_URL}api/get-specific-content-pack-info`,
  API_CREATE_CONTENT_PACK: `${API_URL}api/create-update-content-packs`,
  API_DELETE_ONE_CONTENT_PACK: `${API_URL}api/delete-one-content-pack-info`,
  API_GET_CONTENT_PACK_LIST: `${API_URL}api/get-content-packs-list`,
  API_GET_MEDIA_CONTENT_LIST: `${API_URL}api/get-content-list`,
  API_DELETE_MEDIA_CONTENT: `${API_URL}api/delete-media-content`,
  API_GET_CONTENT_TYPE: `${API_URL}api/get-type`,
  API_CREATE_MEDIA: `${API_URL}api/create-media-content`,
  AUTH_DATA: `${API_URL}api/auth-detail-info-by-id`,
  API_GET_TAG_DATA: `${API_URL}api/get-tag-list`,
  CREATE_TAG: `${API_URL}api/create-tag`,
  DELETE_TAG: `${API_URL}api/delete-tag`,
  EDIT_TAG: `${API_URL}api/edit-tag`,
  API_GET_MEDIA_CONTENT_LIST_FROM_UID_REACT: API_URL + "api/get-media-content-info-from-uid-react",
  API_GET_GEO_DOMAIN_LIST: `${API_URL}api/get-geo-domain-list`,
  API_CREATE_GEO_DOMAIN: `${API_URL}api/create-geo-domain`,
  API_GET_GEO_DOMAIN_INFO: `${API_URL}api/get-geo-domain-info`,
  API_DELETE_GEO_DOMAIN: `${API_URL}api/delete-geo-domain`,
  API_GET_MEDIA_CID_FROM_TITLE: `${API_URL}api/get-media-cid-from-title`,
  API_TO_SAVE_USER_EMAIL_MAILCHIMP: `${API_URL}api/save-email-to-mail-chimp`,
  API_GET_CONTENT_BLOCK_LIST: `${API_URL}api/get-content-blocks-list`,
  API_CREATE_CONTENT_BLOCK: `${API_URL}api/create-content-block-react`,
  API_GET_CONTENT_BLOCK_INFO: `${API_URL}api/get-content-block-info`,
  API_DELETE_CONTENT_BLOCK: `${API_URL}api/delete-content-block-info-react`,
  API_TO_GET_OTP_BY_EMAIL: `${API_URL}api/get-otp-auth`,
  API_TO_VERIFY_JWT_TOKEN_OTP_LOGIN: `${API_URL}api/verify-otp-auth`,
  GETALL_COMPENDIUM_TITLE: `${API_URL}api/getAll-compendium-title`,
  API_TO_PLACE_ORDER: `${API_URL}api/place-order-payment`,
  API_TO_GET_CUSTOM_PUBLICATION_COST_MEDIA_ITEMS: `${API_URL}api/get-publication-costs-for-media-contents`,
  API_TO_GET_SALES_TAX: `${API_URL}api/get-sales-tax-using-zip-code`,
  API_TO_GET_PAY_LINK: `${API_URL}api/get-pay-link-using-paybylink`,
  API_GET_MEDIA_FROM_TYPE: `${API_URL}api/get-media-from-type`,
  API_CREATE_COMPENDIUM: `${API_URL}api/create-compendium-react`,
  API_GET_COMPENDIUM_FROM_ID: `${API_URL}api/get-compendium-from-id`,
  GET_ONE_COMPENDIUM_LIST: `${API_URL}api/get-one-compendium-listing`,
  API_GET_MEDIA_TYPE_FROM_ID: `${API_URL}api/get-media-type-from-id`,
  API_DELETE_COMPENDIUM_INFO: `${API_URL}api/delete-compendium-info`,
  API_CREATE_MEDIA_CONTENT_REACT: `${API_URL}api/create-media-content-react`,
  API_CREATE_CUSTOMER_STRIPE_USING_P_ID: `${API_URL}api/payment/create-stripe-customers-using-p-id`,
  API_GET_SPECIFIC_MEDIA_CATEGORY_ITEM: `${API_URL}api/get-specific-media-category-item`,
  API_GET_DOMAIN_MEDIACONTENT_COUNT: `${API_URL}api/get-domain-media-count`,
  API_CREATE_LANG_INFO_REACT: `${API_URL}api/create-lang-info-react`,
  API_GET_CSV_REPORT_FOR_MEDIA_CONTENT: `${API_URL}api/get-csv-data-for-media-content`,
  API_GET_UNIQUE_USER_INFO: `${API_URL}api/get-user-info`,
  API_DELETE_LANG_TEXT: `${API_URL}api/delete-specific-lang-text`,
  API_CREATE_SITE_COLLINSION: `${API_URL}api/create-site-collinson`,
  API_USE_LOGIN_VOUCHER_CODE: `${API_URL}api/use-vcode`,
  API_FOR_VOUCHER_ACCESS_CHECK: `${API_URL}api/visitor-access`,
  API_GET_DOMAIN_CONTENT_PACK: `${API_URL}api/get-domain-content-pack`,
  API_GET_DOMAIN_MEDIA_CONTENT: `${API_URL}api/get-domain-media-content`,
  API_GET_VOUCHER_LIST: `${API_URL}api/voucher-status`,
  API_TO_GET_FLIGHT_STATUS: `${API_URL}api/get-flight-status`,
  API_GET_EDITOR_CONTENT_LISTS: `${API_URL}api/get-dm-editor-content-list`,
  API_GET_CONTENT_PAGE_LIST: `${API_URL}api/get-all-content-pages`,
  API_REMOVE_CONTENT_PAGE_LIST: `${API_URL}api/delete-content-page-info`,
  API_GET_SINGLE_CONTENT_PAGE: `${API_URL}api/get-dm-content-page-info`,
  API_UPDATE_CONTENT_PAGE: `${API_URL}api/update-content-page-info`,
  API_CREATE_CONTENT_PAGE: `${API_URL}api/create-content-page-info`,
  API_GET_DOMAINS_COMPENDIUM_LIST: `${API_URL}api/get-domain-compendium-list`,
  API_TO_GET_PREMIUM_CONTENT_LISTING: `${API_URL}api/get-premium-content-listing`,
  API_TO_ADD_EDIT_PREMIUM_CONTENT_DETAILS: `${API_URL}api/add-edit-premium-content-details`,
  API_GET_ALL_MEDIA_CONTENT_LIST: `${API_URL}api/get-all-media-content-list`,
  API_TO_GET_DOMAIN_INFO_LIST: `${API_URL}api/get-domain-info-list`,
  API_TO_GET_DOMAIN_INFO_LIST_TO_EDIT: `${API_URL}api/get-domain-info-list-to-edit`,
  API_REMOVE_PREMIUM_CONTENT_INFO: `${API_URL}api/delete-premium-content`,
  API_TO_GET_PREMIUM_CONTENT_OTP_BY_EMAIL: `${API_URL}api/get-otp-auth-for-premium-content`,
  API_TO_ADD_UPDATE_PREMIUM_USER_DATA_FOR_EVENT_LOG: `${API_URL}api/add-update-premium-user-data-for-event-log`,
  API_ASSIGN_DOMAIN_MENU: `${API_URL}api/assign-domain-menu-item`,
  API_GET_DOMAIN_MENUS: `${API_URL}api/get-domain-menu-item`,
  API_CHECK_OTP_AUTH: `${API_URL}api/check-otp-auth`,
  API_INVALIDATE_OTP: `${API_URL}api/invalidate-otp-link`,
  API_GET_AUTOMATED_PUBLICATION_LIST: `${API_URL}api/get-automates-publication-lits`,
  API_GET_FILTER_PUBLICATION_LIST: `${API_URL}api/get-filter-publication-lits`,
  API_SAVE_USER_INFO_SELL_MAILCHIMP: `${API_URL}api/store-users-details-mailchimp`,
  API_GET_MEDIA_CONTENT_DETAIL: `${API_URL}api/get-media-content-info-detail`,
  API_GET_PERMISSION_LIST: `${API_URL}api/get-permission-list`,
};
