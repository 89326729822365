import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import adminMediaContentServices from '../components/services/adminMediaContentServices.js';
import { decryptIt } from '../components/common/commonHelper.jsx';


export const adminMCListInfo = createAsyncThunk('adminMediaContentListInfo/adminMCListInfo', async ({ qprm }) => {
    try {

        const response = await adminMediaContentServices.adminGetMediaContentList({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const getMediaCategoryList = createAsyncThunk('adminDomainInfo/getMediaCategoryList', async ({ qprm }) => {
    try {
        const response = await adminMediaContentServices.adminGetMediaCategoryList({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const getGroupedMediaContentTypes = createAsyncThunk('adminDomainInfo/getGroupedMediaContentTypes', async () => {
    try {
        const response = await adminMediaContentServices.adminGetGroupedMediaContentTypes();
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const getMediaContentCIDUsingSearch = createAsyncThunk('adminDomainInfo/getMediaContentCIDUsingSearch', async () => {
    try {
        const response = await adminMediaContentServices.adminGetMediaContentCIDUsingSearch();
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const getMediaContentFromUid = createAsyncThunk('/adminDomainInfo/getMediaContentFromUid', async ({ qprm }) => {
    try {
        const response = await adminMediaContentServices.adminGetMediaContentFromId({ qprm })
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data
    }
})

export const getMediaContentType = createAsyncThunk('/adminDomainInfo/getMediaContentType', async () => {
    try {
        const response = await adminMediaContentServices.adminGetMediaContentTypes()
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data
    }
})

export const deleteMediaContent = createAsyncThunk('adminDeleteMediaContemt/deleteMediaContent', async ({ mediaObjId }) => {
    try {
        const response = await adminMediaContentServices.adminDeleteMediaContemt({ mediaObjId })
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data
    }
});

export const getMediaContentCIDFromTitle = createAsyncThunk('adminGetMediaCidFromTitle/getMediaContentCIDFromTitle', async ({ qprm }) => {
    try {
        const response = await adminMediaContentServices.adminGetMediaCidFromTitle({ qprm })
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data
    }
})

export const getMediaContentFromType = createAsyncThunk('/admin', async ({ qprm }) => {
    try {
        const response = await adminMediaContentServices.adminGetMediaContentFromType({ qprm })
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data
    }
})

export const adminGetMediaTypeFromId = createAsyncThunk('/admin', async ({ qprm }) => {
    try {
        const response = await adminMediaContentServices.adminGetMediaTypeFromId({ qprm })
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data
    }
})

export const adminGetDomainMediaCount = createAsyncThunk('/admin', async ({ qprm }) => {
    try {
        const response = await adminMediaContentServices.adminGetDomainMediaContentCount({ qprm })
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data
    }
})

export const adminGetCSVForMediaContent = createAsyncThunk('/admin', async ({ qprm }) => {
    try {
        const response = await adminMediaContentServices.adminGetCSVForMediaContent({ qprm })
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data
    }
})

export const getAllMediaContentList = createAsyncThunk('adminDomainInfo/getAllMediaContentList', async () => {
    try {
        const response = await adminMediaContentServices.adminGetAllMediaContentList();
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const getPremiumContentList = createAsyncThunk('adminDomainInfo/getPremiumContentList', async () => {
    try {
        const response = await adminMediaContentServices.adminGetPremiumContentList();
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const removePremiumContent = createAsyncThunk('adminDomainInfo/removePremiumContent', async ({ qprm }) => {
    try {
        const response = await adminMediaContentServices.adminRemovePremiumContent({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const adminMediaContentSlice = createSlice({
    name: 'adminMediaContentListInfo',
    initialState: {
        loading: false,
        error: null,
        data: [],
        filteredMediaData: [],
        mediaContentData: [],
        notFound: false
    },
    reducers: {
        filteredMediaList: (state, action) => {
            state.filteredMediaData = action.payload
        }
    },
    extraReducers: {
        [adminMCListInfo.pending]: (state) => {
            state.loading = true;
        },
        [adminMCListInfo.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminMCListInfo.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
            if (action.payload && action?.payload?.data && action.payload?.status) {
                state.mediaContentData = JSON.parse(decryptIt(action?.payload?.data)?.slice(1, -1))?.data;
            } else {
                state.notFound = action.payload?.message || true
                state.mediaContentData = []

            }
        },
        [getMediaCategoryList.pending]: (state) => {
            state.loading = true;
        },
        [getMediaCategoryList.rejected]: (state) => {
            state.loading = false;
        },
        [getMediaCategoryList.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [getGroupedMediaContentTypes.pending]: (state) => {
            state.loading = true;
        },
        [getGroupedMediaContentTypes.rejected]: (state) => {
            state.loading = false;
        },
        [getGroupedMediaContentTypes.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [getMediaContentCIDUsingSearch.pending]: (state) => {
            state.loading = true;
        },
        [getMediaContentCIDUsingSearch.rejected]: (state) => {
            state.loading = false;
        },
        [getMediaContentCIDUsingSearch.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminGetMediaTypeFromId.pending]: (state) => {
            state.loading = true;
        },
        [adminGetMediaTypeFromId.rejected]: (state) => {
            state.loading = false;
        },
        [adminGetMediaTypeFromId.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [getMediaContentFromType.pending]: (state) => {
            state.loading = true;
        },
        [getMediaContentFromType.rejected]: (state) => {
            state.loading = false;
        },
        [getMediaContentFromType.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminGetDomainMediaCount.pending]: (state) => {
            state.loading = true;
        },
        [adminGetDomainMediaCount.rejected]: (state) => {
            state.loading = false;
        },
        [adminGetDomainMediaCount.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminGetCSVForMediaContent.pending]: (state) => {
            state.loading = true;
        },
        [adminGetCSVForMediaContent.rejected]: (state) => {
            state.loading = false;
        },
        [adminGetCSVForMediaContent.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [getPremiumContentList.pending]: (state) => {
            state.loading = true;
        },
        [getPremiumContentList.rejected]: (state) => {
            state.loading = false;
        },
        [getPremiumContentList.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [removePremiumContent.pending]: (state) => {
            state.loading = true;
        },
        [removePremiumContent.rejected]: (state, action) => {
            state.loading = false;
        },
        [removePremiumContent.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [getAllMediaContentList.pending]: (state) => {
            state.loading = true;
        },
        [getAllMediaContentList.rejected]: (state) => {
            state.loading = false;
        },
        [getAllMediaContentList.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
    }
})

export const { filteredMediaData } = adminMediaContentSlice.actions
export default adminMediaContentSlice.reducer;
