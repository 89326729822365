import * as actions from "../../actions/actionTypes";
import { VI_IMAGES_URL } from "../../constants/commonconstants";
import store from '../../store/store';
import AuthenticatedOperations from "../staff-portals/AuthenticatedOperations";
import axios from "axios";
import moment from 'moment';
const crypto = require("crypto");
const languages = require('./../../lang/lang.json');

export function getJwtToken() {
    try {
        let tokendata = store.getState();
        if (tokendata !== '') {
            if (typeof tokendata['jwtToken'] !== 'undefined' && tokendata['jwtToken'] !== '') {
                return decryptIt(tokendata['jwtToken']).slice(1, -1);
            }
        }
        return '';
    } catch (err) {
        return '';
    }
}

export function getDomainId() {
    try {
        let tokendata = store.getState();
        if (tokendata !== '') {
            if (typeof tokendata['did'] !== 'undefined' && tokendata['did'] !== '') {
                return decryptIt(tokendata['did']).slice(1, -1);
            }
        }
        return '';
    } catch (err) {
        return '';
    }
}

export function getDomainInfo(info) {
    try {
        let tokendata = store.getState();
        if (tokendata !== '') {
            if (typeof tokendata[info] !== 'undefined' && tokendata[info] !== '') {
                return decryptIt(tokendata[info]).slice(1, -1);
            }
        }
        return '';
    } catch (err) {
        return '';
    }
}

export async function setVIInfo(viStorage) {
    let viInfoDispatch = {
        type: actions.VIINFO,
        payload: {
            did: (typeof viStorage[0] !== 'undefined' && viStorage[0] !== '' && viStorage[0] !== null) ? viStorage[0] : '',
            domainMachineName: (typeof viStorage[1] !== 'undefined' && viStorage[1] !== '' && viStorage[1] !== null) ? viStorage[1] : '',
            domainName: (typeof viStorage[2] !== 'undefined' && viStorage[2] !== '' && viStorage[2] !== null) ? viStorage[2] : '',
            domainDistributor: (typeof viStorage[3] !== 'undefined' && viStorage[3] !== '' && viStorage[3] !== null) ? viStorage[3] : '',
            domainVIsetting: (typeof viStorage[4] !== 'undefined' && viStorage[4] !== '' && viStorage[4] !== null) ? viStorage[4] : '',
            userCountry: (typeof viStorage[6] !== 'undefined' && viStorage[6] !== '' && viStorage[6] !== null) ? viStorage[6] : '',
            userIp: (typeof viStorage[7] !== 'undefined' && viStorage[7] !== '' && viStorage[7] !== null) ? viStorage[7] : '',
        }
    };
    if ((typeof viStorage[8] !== 'undefined' && viStorage[8] !== '' && viStorage[8] !== null)) {
        viInfoDispatch['payload']['jwtToken'] = viStorage[8];
    }
    await store.dispatch(viInfoDispatch);
}

export function getVIInfo() {
    try {
        var vidata = store.getState();
        if (vidata !== '') {
            if (Object.keys(vidata).length !== 0 || vidata['domainVIsetting'] !== '') {
                let vset = decryptIt(vidata['domainVIsetting']);
                let parsedSettings = JSON.parse(vset.slice(1, -1));
                return parsedSettings;
            }
        }
        return '';
    } catch (err) {
        return '';
    }
}


export function checkUserLogin() {
    try {
        let saved_uinfo = localStorage.getItem('userName');
        if (typeof saved_uinfo !== 'undefined' && saved_uinfo !== null && saved_uinfo !== '') {
            return true;
        }
        return false;
    } catch (err) {
        return false;
    }
}

export function ucwords(str) {
    return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
        return $1.toUpperCase();
    });
}

export function capitalizeFirstLetter(str) {
    return str?.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

export function capitalizeFirstCharString(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function ipAddressLong2shorthand(start_ip, end_ip) {
    const long2ip = require('long2ip');
    let result = '';
    if (start_ip === end_ip) {
        result = long2ip(start_ip);
    } else {
        let start = long2ip(start_ip).toString().split('.');
        let end = long2ip(end_ip).toString().split('.');
        if (start[0] === end[0] && start[1] === end[1] && start[2] === end[2]) {
            if (start[3] === '0' && end[3] === '255') {
                start[3] = '*';
                result = start.join('.');
            } else {
                start[3] = start[3] + '-' + end[3];
                result = start.join('.');
            }
        } else {
            result = long2ip(start_ip) + '-' + long2ip(end_ip);
        }
    }
    return result;
}

export function encryptIt(string, noMultiEnc) {
    const encKey = process.env.REACT_APP_ENC_KEY || '9Q7CM34P378078969Q7CM34P37807896';
    try {
        let key = Buffer.from(encKey, 'binary')
        let iv = crypto.randomBytes(16);
        var cipher = crypto.createCipheriv('aes-256-cbc', key, iv)
        var encString = cipher.update('"' + string + '"');
        encString = Buffer.concat([encString, cipher.final()]);

        let finalEncString = iv.toString('hex') + '!$!' + encString.toString('hex') + '!$!' + crypto.randomBytes(5).toString('hex');
        if (typeof noMultiEnc === 'undefined' && !noMultiEnc) {
            finalEncString = encryptVal(finalEncString);
            finalEncString = finalEncString + '!$@$!' + crypto.randomBytes(3).toString('hex');
        }
        return finalEncString;

    } catch (err) {
        return 0;
    }
}
export function decryptIt(encString) {
    const encKey = process.env.REACT_APP_ENC_KEY || '9Q7CM34P378078969Q7CM34P37807896';
    try {
        let strEncArr = encString.split('!$@$!');
        let string = (strEncArr.length == 2) ? decryptVal(strEncArr[0]) : encString;

        let strArr = string.split('!$!');
        let key = Buffer.from(encKey, 'binary');
        let iv = Buffer.from(strArr[0], 'hex');
        string = Buffer.from(strArr[1], 'hex');
        var decipher = crypto.createDecipheriv('aes-256-cbc', key, iv)
        var decString = decipher.update(string)
        decString = Buffer.concat([decString, decipher.final()]);
        return decString.toString();
    } catch (err) {
        return 0;
    }
}

export function encryptVal(string) {
    if (!isNaN(string)) {
        string = parseInt(string) + parseInt(string);
    }
    string = btoa(string);
    return string.replace(/=+$/, '');
}

export function decryptVal(string) {
    string = atob(string);
    if (!isNaN(string)) { return string / 2; }
    return string;
}

export function getFileExtension(filename) {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
}

export function checkAndGetImage(filePath) {
    try {
        if (typeof filePath !== 'undefined' && filePath !== null && filePath !== '') {
            var pathParse = require('path-parse');
            var fileInfoObj = pathParse(filePath);
            if (typeof (fileInfoObj) !== 'undefined' && fileInfoObj !== null && fileInfoObj !== '') {
                return fileInfoObj;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (err) {
        return false;
    }
}

export async function fileExists(url) {
    let status = false;
    return await fetch(url, { method: 'HEAD' })
        .then((response) => {
            debugLogConsoleInfo(response, 'response');
            debugLogConsoleInfo(response.status, 'response.status');
            debugLogConsoleInfo(response.ok, 'response.ok');
            status = typeof response.ok !== 'undefined' ? response.ok : false;
            return status;
        }).catch((error) => {
            debugLogConsoleInfo(error, 'error');
            return false;
        });
}

export function getUniqueRandomVal() {
    let min = 10000;
    let max = 999999999999999;
    return min + Math.random() * (max - min);
}

export function arrayColumn(array, columnName) {
    return array.map(function (value, index) {
        return value[columnName];
    })
}

export function isFrontPage() {
    if (typeof window !== 'undefined') {
        let currentPath = trimFromEnd(window.location.pathname, "/");
        return currentPath === '/' || currentPath === '/' + getCurrentLang();
    }
}

export function getCurrentLang() {
    let lang = window.localStorage.getItem("i18nextLng");
    let supportedLanguages = Object.keys(languages);
    lang = (supportedLanguages.includes(lang) ? (!lang.includes('en') ? lang : "en") : "en");
    return lang;
}

export function getLangDetails(language) {
    let supportedLanguages = Object.keys(languages);
    return (supportedLanguages.includes(language)) ? languages[language] : languages['en'];
}

export function debugLogConsoleInfo(data, statement) {
    let isDebugMode = process.env.REACT_APP_IS_DEBUG_MODE || false;
    if (isDebugMode) {
        if (typeof statement !== 'undefined') {
            console.log(statement, data);
        } else {
            console.log(data);
        }
    }
}

export function arrayEquals(a, b) {
    if (Array.isArray(a)) { a.sort(); }
    if (Array.isArray(b)) { b.sort(); }
    debugLogConsoleInfo(a, 'a');
    debugLogConsoleInfo(b, 'b');
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}

export function handleLoader(isHide, timeout) {
    if (typeof timeout === 'undefined') { timeout = 0; }
    setTimeout(function () {
        if (isHide) {
            window.$('#mp-loader').hide();
        } else {
            window.$('#mp-loader').show();
        }
    }, timeout);
}

export const loading = () => <div id="mp-loader" className="filter-menu-loading filter-menu-loader-show"><div className="loader-show"><div className="loader"><svg className="circular" viewBox="25 25 50 50"><circle className="path1" cx="50" cy="50" r="20" fill="none" stroke="#fff" strokeWidth="2"></circle><circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"></circle></svg></div></div></div>;

export function imageExist(url) {
    let img = new Image();
    img.src = url;
    return img.height != 0;
}

export function stringIsAValidUrl(url) {
    let status = false;
    try {
        const validUrl = require('valid-url');
        if (validUrl.isUri(url)) {
            status = true
        }
    } catch (err) {
        status = false;
    }
    return status;
};

export function trimFromEnd(stringToTrimFrom, charToTrim) {
    if ((stringToTrimFrom !== charToTrim) && (stringToTrimFrom.charAt(stringToTrimFrom.length - 1) === charToTrim)) {
        stringToTrimFrom = stringToTrimFrom.substr(0, stringToTrimFrom.length - 1);
    }
    return stringToTrimFrom;
}

export function stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    let returnTxt = tmp.textContent || tmp.innerText || "";
    tmp.remove();
    return returnTxt;
}

export function getCognitoUserSessionObj() {
    let authenticatedOperationsObj = {};
    if (typeof sessionStorage.getItem('cognitoUser') !== "undefined" && sessionStorage.getItem('cognitoUser') !== null && sessionStorage.getItem('cognitoUser') !== "") {
        const getSession = JSON.parse(decryptVal(sessionStorage.getItem('cognitoUser')))
        const { username, signInUserSession } = getSession;
        const { accessToken, idToken, refreshToken } = signInUserSession
        authenticatedOperationsObj = new AuthenticatedOperations({
            username,
            idToken: idToken.jwtToken,
            accessToken: accessToken.jwtToken,
            refreshToken: refreshToken.token
        })
    }
    return authenticatedOperationsObj;
}

export function strReplace(findArray, replaceArray, str) {
    var i, regex = [], map = {};
    for (i = 0; i < findArray.length; i++) {
        regex.push(findArray[i].replace(/([-[\]{}()*+?.\\^$|#,])/g, '\\$1'));
        map[findArray[i]] = replaceArray[i];
    }
    regex = regex.join('|');
    str = str.replace(new RegExp(regex, 'g'), function (matched) {
        return map[matched];
    });
    return str;
}

export async function commonTimekitFunc(url, method, data) {
    let timekitResp = false;
    let timekitKey = process.env.REACT_APP_COLLINSON_TIMEKIT_KEY || 'OmxpdmVfYXBpX2tleV9jMUs0SzJqbnBJVXhwUWFZVG9RdjB5WVBwTUFkS0p5Uw';
    if (typeof data === 'undefined') data = false;
    let options = {
        method: method,
        headers: { accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': `Basic ${timekitKey}` }
    };
    if (data) {
        options['body'] = JSON.stringify(data);
    }
    return await fetch(url, options)
        .then((response) => response.json())
        .then((response) => {
            timekitResp = response;
            return timekitResp;
        }).catch((err) => {
            console.log(err);
            return false;
        });
}

export function handleKeyDown(e, ref) {
    if (e.key === 'Enter' || e.type === 'click') {
        ref.current.click();
    }
}

export function getTimezoneCurrentTime(timezone, returnInMiliseconds) {
    if (typeof timezone === 'undefined') {
        timezone = 'Europe/Dublin';
    }
    if (typeof returnInMiliseconds === 'undefined') {
        returnInMiliseconds = false;
    }
    let options = {
        timeZone: timezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    };
    const dateTimeFormat = new Intl.DateTimeFormat('en', options)
    const [{ value: month }, , { value: day }, , { value: year }, , { value: hour }, , { value: minute }, , { value: second }] = dateTimeFormat.formatToParts(new Date())
    if (returnInMiliseconds) {
        var currentTime = Date.parse(new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}Z`).toUTCString())
    } else {
        var currentTime = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}Z`);
    }
    return currentTime;
}

export function getDateTimeFormat(dateToFormat, format, timeZone, options) {
    if (typeof timeZone === 'undefined') {
        timeZone = 'Europe/Dublin';
    }
    if (typeof dateToFormat !== 'undefined') {
        if (typeof options == 'undefined') {
            options = {
                timeZone: timeZone,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            };
        }
        const dateTimeFormat = new Intl.DateTimeFormat('en', options)
        const [{ value: month }, , { value: day }, , { value: year }, , { value: hour }, , { value: minute }, , { value: second }] = dateTimeFormat.formatToParts(new Date(dateToFormat))
        switch (format) {
            case 'd-m-y':
                return `${day}-${month}-${year}`;
                break;
            case 'y-m-d':
                return `${year}-${month}-${day}`;
                break;
            case 'Y-m-d H:i:s':
                return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
                break;
            case 'd.m.y':
            default:
                return `${day}.${month}.${year}`;
                break;
        }
    }
}

export function getDateTimeInFormat(timeToFormat, format) {
    let currentYear = timeToFormat.getFullYear(), currentMonth = timeToFormat.getMonth() + 1, currentDate = timeToFormat.getDate(), currentHours = timeToFormat.getHours(), currentMinutes = timeToFormat.getMinutes(), currentSeconds = timeToFormat.getSeconds(), currentDay = timeToFormat.getDay();
    if (currentMonth < 10) {
        currentMonth = '0' + currentMonth;
    }
    if (currentDate < 10) {
        currentDate = '0' + currentDate;
    }
    if (currentHours < 10) {
        currentHours = '0' + currentHours;
    }
    if (currentMinutes < 10) {
        currentMinutes = '0' + currentMinutes;
    }
    if (currentSeconds < 10) {
        currentSeconds = '0' + currentSeconds;
    }
    switch (format) {
        case 'Y-m-d':
            return `${currentYear}-${currentMonth}-${currentDate}`;
        case 'H:i':
            return `${currentHours}:${currentMinutes}`;
        case 'l':
            return dayInString(currentDay);
        case 'd M Y H:i':
            return `${currentDate} ${monthInString(currentMonth - 1, true)} ${currentYear} ${currentHours}:${currentMinutes}`;
        case 'Y-m-d H:i:s':
            return `${currentYear}-${currentMonth}-${currentDate} ${currentHours}:${currentMinutes}:${currentSeconds}`;
    }
}

export function dayInString(dayInInt) {
    const dayArr = { 0: "Sunday", 1: "Monday", 2: "Tuesday", 3: "Wednesday", 4: "Thursday", 5: "Friday", 6: "Saturday" };
    return (dayInInt >= 0 && dayInInt < 7) ? dayArr[dayInInt] : '';
}

export function monthInString(monthInInt, is_short) {
    if (typeof is_short === 'undefined') {
        is_short = false;
    }
    const monthNamesArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthNamesShortArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (monthInInt >= 0 && monthInInt < 12) ? (is_short ? monthNamesShortArr[monthInInt] : monthNamesArr[monthInInt]) : '';
}

export async function commonMandrillFunc(payLoad) {
    let mandrillKey = process.env.REACT_APP_MAILDRILL_KEY || 'GJWw-F1VBOn6YXpxw8sqvA';
    payLoad = payLoad || {};
    const mailchimpClient = require("@mailchimp/mailchimp_transactional")(
        mandrillKey
    );
    const response = await mailchimpClient.messages.sendTemplate(payLoad);
    return response;
}

export function trapFocus(elem, elemToFocus) {
    if (typeof elemToFocus == 'undefined') {
        elemToFocus = false;
    }
    var tabbable = elem.find('select, input, textarea, button, a, [tabindex="0"]').filter(':visible');

    var firstTabbable = tabbable.first();
    var lastTabbable = tabbable.last();
    /*set focus on first input*/
    if (!elemToFocus) {
        setTimeout(() => {
            var divElement = document.getElementById("wrapper");
            divElement.setAttribute('aria-hidden', 'true');
            firstTabbable.focus();
        }, 500)
    } else {
        elemToFocus.focus();
    }

    /*redirect last tab to first input*/
    lastTabbable.on('keydown', function (e) {
        if ((e.which === 9 && !e.shiftKey) || (e.which === 39) || (e.which === 40)) {
            e.preventDefault();
            firstTabbable.focus();
        }
    });

    /*redirect first shift+tab to last input*/
    firstTabbable.on('keydown', function (e) {
        if ((e.which === 9 && e.shiftKey) || (e.which === 37) || (e.which === 38)) {
            e.preventDefault();
            lastTabbable.focus();
        }
    });
}

//common function for mixPanel
export function getMixPanel() {
    try {
        const mixpanel = require("mixpanel-browser");
        const { v1: uuidv1 } = require('uuid');
        let vi_settings = getVIInfo();
        let uuid = null;
        // on based on vi_settings create  
        if (typeof localStorage.getItem(`__${vi_settings?.analytic_tracking_code}_visitor_uuid`) !== "undefined" && localStorage.getItem(`__${vi_settings.analytic_tracking_code}_visitor_uuid`) !== null && localStorage.getItem(`__${vi_settings.analytic_tracking_code}_visitor_uuid`) !== "") {
            uuid = localStorage.getItem(`__${vi_settings.analytic_tracking_code}_visitor_uuid`);
            uuid = encryptIt(uuid);
        } else {
            uuid = uuidv1();
            localStorage.setItem('unique_uuid', encryptIt(uuid));
            uuid = encryptIt(uuid);
        }
        let actions = {
            track: (name, props) => {
                let defaultTrackingProps = { "uid": decryptIt(uuid).slice(1, -1) };
                if (process.env.REACT_APP_ENV == "production") {
                    mixpanel.init(vi_settings.mix_panel_api_key, { debug: false, ignore_dnt: true, });
                    mixpanel.track(name, { ...props, ...defaultTrackingProps });
                }
            },
            uuid: uuid
        };
        return actions;
    } catch (error) {
        console.log('mixpanel error', error)
    }
}
export function scrollToTopOfElement(element, duration) {
    const elementTop = element.getBoundingClientRect().top + window.pageYOffset;
    const start = window.pageYOffset;
    const distance = elementTop - start;
    const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

    function scrollAnimation(currentTime) {
        const elapsedTime = currentTime - startTime;
        const scrollY = easeInOutCubicInterpolator(elapsedTime, start, distance, duration);
        window.scrollTo(0, scrollY);

        if (elapsedTime < duration) {
            requestAnimationFrame(scrollAnimation);
        }
    }

    function easeInOutCubicInterpolator(t, b, c, d) {
        t /= d / 2;
        if (t < 1) {
            return (c / 2) * t * t * t + b;
        }
        t -= 2;
        return (c / 2) * (t * t * t + 2) + b;
    }

    requestAnimationFrame(scrollAnimation);
}

export function downloadCsv(csvContent, fileName) {
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
}

export function latlngdistance(lat1, lon1, lat2, lon2, unit) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
    } else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit == "K") {
            dist = dist * 1.609344
        }
        if (unit == "N") {
            dist = dist * 0.8684
        }
        return dist;
    }
}

export async function checkAccess() {
    localStorage.removeItem(encryptVal('userLatitude'));
    localStorage.removeItem(encryptVal('userLongitude'));
    localStorage.removeItem(encryptVal('contentIPAllowed'));
    var myIp = getDomainInfo('userIp');
    var ip_arr = [];
    let vi_settings = getVIInfo();
    var site_block_type = typeof vi_settings['site_block_type'] !== 'undefined' ? vi_settings['site_block_type'].toString() : '0';
    let use_location_blocking = vi_settings?.use_location_blocking || "";
    let allowed = false;

    switch (site_block_type) {
        case '0':
        default:
            ip_arr.push('0.0.0.0');
            break;
        case '1':
            if ((typeof vi_settings['ip_address'] !== 'undefined' && vi_settings['ip_address'].length) || (typeof vi_settings['system_ip_four'] !== 'undefined' && vi_settings['system_ip_four'].length) || (typeof vi_settings['right_ipv_six'] !== 'undefined' && vi_settings['right_ipv_six'].length) || (typeof vi_settings['system_ip_six'] !== 'undefined' && vi_settings['system_ip_six'].length)) {
                let ip4_address = [];
                if (typeof vi_settings['ip_address'] !== 'undefined' || vi_settings['ip_address'].length) {
                    ip4_address = vi_settings['ip_address'];
                }
                let system_ip4_address = [];
                if (typeof vi_settings['system_ip_four'] !== 'undefined' || vi_settings['system_ip_four'].length) {
                    system_ip4_address = vi_settings['system_ip_four'];
                }
                ip4_address = ip4_address.concat(system_ip4_address);
                if (ip4_address.length) {
                    ip4_address.forEach((val, index) => {
                        let sub_ip4_add = [];
                        let ip4_add = [];
                        let ip4 = ipAddressLong2shorthand(val.start, val.end);
                        if (ip4.indexOf('-') !== -1) {
                            let ip_add_range = ip4.toString().split('-');
                            let start_ip_range = ip_add_range[0];
                            let end_ip_range = ip_add_range[1];
                            let start_ip = start_ip_range.toString().split('.');
                            let from_start = start_ip[start_ip.length - 1];
                            for (let j = (parseInt(from_start)); j <= parseInt(end_ip_range); j++) {
                                let add_to_push = start_ip[0] + '.' + start_ip[1] + '.' + start_ip[2] + '.' + j;
                                sub_ip4_add.push(add_to_push);
                            }
                        } else {
                            ip4_add.push(ipAddressLong2shorthand(val.start, val.end));
                        }
                        ip_arr = ip_arr.concat(ip4_add, sub_ip4_add);
                    });
                }

                let ip6_address = [];
                if (typeof vi_settings['right_ipv_six'] !== 'undefined' || vi_settings['right_ipv_six'].length) {
                    ip6_address = vi_settings['right_ipv_six'];
                }
                let system_ip6_address = [];
                if (typeof vi_settings['system_ip_six'] !== 'undefined' || vi_settings['system_ip_six'].length) {
                    system_ip6_address = vi_settings['system_ip_six'];
                }
                ip6_address = ip6_address.concat(system_ip6_address);
                if (ip6_address.length) {
                    var ipRangeCheck = require("ip-range-check");
                    ip6_address.forEach((ip6val, index) => {
                        let ip6_add = ip6val.toString().split('|');
                        if (typeof ip6_add[0].trim() !== 'undefined') {
                            ip6_add[0] = ip6_add[0].trim();
                            if (ip6_add[0].indexOf('/') !== -1) {
                                if (ipRangeCheck(myIp, ip6_add[0])) {
                                    ip_arr.push(myIp);
                                }
                            } else {
                                ip_arr.push(ip6_add[0].trim());
                            }
                        }
                    });
                }
            }
            break;
    }

    if (site_block_type === '0' || site_block_type === '1') {
        if (ip_arr.length) {
            if (ip_arr.includes(myIp.toString())) {
                localStorage.setItem(encryptVal("contentIPAllowed"), true);
                allowed = true;
            } else {
                allowed = false;
            }
        }
    }

    if (!allowed && use_location_blocking == 1) {
        // Wrap getCurrentPosition in a Promise
        const userLocation = await new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    async position => {
                        var lat2 = position.coords.latitude;
                        var lng2 = position.coords.longitude;
                        resolve({ lat: lat2, lng: lng2 });
                    },
                    error => {
                        console.log("error", error);
                        allowed = false;
                        resolve(error);
                    },
                    {
                        enableHighAccuracy: true,
                        maximumAge: 0,
                    }
                );
            } else {
                reject("Geolocation not supported");
            }
        });

        if (userLocation?.lat && userLocation?.lng) {
            let distance_blocking = parseFloat(vi_settings['distance_blocking']);
            let venue_geo_details = vi_settings['venue_geo_details'];
            localStorage.setItem(encryptVal('userLatitude'), encryptIt(userLocation.lat));
            localStorage.setItem(encryptVal('userLongitude'), encryptIt(userLocation.lng));

            // Check the user's location against venue_geo_details
            venue_geo_details.forEach(async (value, index) => {
                if (typeof value != 'undefined' && value != '') {
                    var valsplit = value.split("|");
                    if (typeof valsplit[0] != 'undefined') {
                        var latlngstr = valsplit[0];
                        var latlngsplit = latlngstr.split(',');
                        if (typeof latlngsplit[0] != 'undefined' && typeof latlngsplit[1] != 'undefined') {
                            var lat1 = parseFloat(latlngsplit[0].trim());
                            var lng1 = parseFloat(latlngsplit[1].trim());
                            var latlngdis = latlngdistance(lat1, lng1, userLocation.lat, userLocation.lng, 'K');
                            if (typeof latlngdis != 'undefined' && latlngdis <= distance_blocking) {
                                // console.log("matched distance");
                                localStorage.setItem(encryptVal("contentIPAllowed"), true);
                                allowed = true;
                                return false;
                            }
                        }
                    }
                }
            });
        }
    }
    return allowed;
}

export async function convertImagePathToBase64(imageUrl) {
    try {
        const imageResponse = await axios.get(imageUrl, {
            responseType: "arraybuffer",
        });
        const imgBase64 = `data:${imageResponse.headers["content-type"]
            };base64,${Buffer.from(imageResponse.data).toString("base64")}`;
        //   console.log("imgBase64",imgBase64);  
        return imgBase64;
    } catch (err) {
        return {
            status: 0,
            message: err.message,
        };
    }
}

export function getPublicationStatus(frequencyType, publishDate, nid = null) {
    try {
        if (typeof frequencyType !== 'undefined' && frequencyType !== null && frequencyType !== '' &&
            typeof publishDate !== 'undefined' && publishDate !== null && publishDate !== '') {
            const currentDate = moment();
            const revDate = moment(publishDate);
            let dotClass = 'red-dot';
            let afterDate = '';
            switch (frequencyType) {
                case 2:
                    afterDate = revDate.clone().add(7, 'days');
                    break;
                case 3:
                    afterDate = revDate.clone().add(15, 'days');
                    break;
                case 4:
                    afterDate = revDate.clone().add(1, 'month');
                    break;
                case 5:
                    afterDate = revDate.clone().add(2, 'months');
                    break;
                case 6:
                    afterDate = revDate.clone().add(3, 'months');
                    break;
                case 7:
                    afterDate = revDate.clone().add(6, 'months');
                    break;
                case 8:
                    afterDate = revDate.clone().add(1, 'year');
                    break;
                case 9:
                    dotClass = 'green-dot';
                    break;
                default:
                    const yesterdayDate = moment().subtract(1, 'days');
                    const sixPmTime = yesterdayDate.clone().hour(18).startOf('hour');
                    const twelveAmTime = currentDate.clone().startOf('day');
                    if (currentDate.isSame(revDate, 'day') || (revDate.isSameOrAfter(sixPmTime) && revDate.isSameOrBefore(twelveAmTime))) {
                        dotClass = 'green-dot';
                    }
                    if (nid && nid === '5fa68f14c2eaa24fc214ec77') {
                        const tomorrowDate = moment().add(1, 'days');
                        if (currentDate.isBetween(revDate, tomorrowDate)) {
                            dotClass = 'green-dot';
                        }
                    }
                    break;
            }
            if (afterDate !== '') {
                if (dotClass !== 'green-dot') {
                    if (currentDate.isBetween(revDate, afterDate)) {
                        dotClass = 'green-dot';
                    }
                }
            }
            const formattedPublishDate = revDate.utc().format('DD-MM-YYYY hh:mm A');
            return { dotClass, formattedPublishDate };
        }
    } catch (err) {
        return {
            status: 0,
            message: err.message,
        };
    }
}

//common function to traverse recursively each item from the html element
export function extractTranslatableTextFromHTML(htmlContent, t) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    let translatedObj = {};
    const traverseHtml = (node, transFunction) => {
        if (node.nodeName !== 'STYLE') {
            if (node.nodeType === Node.TEXT_NODE) {
                const textContent = node.textContent.trim();
                if (textContent !== '') {
                    translatedObj[textContent] = transFunction(textContent);
                }
            } else {
                node.childNodes.forEach(childNode => traverseHtml(childNode, transFunction));
            }
        }
    }

    traverseHtml(tempDiv, t);
    return translatedObj;
};

export function getViImagesPath(imgItem, svgInfo) {
    let imgPath = ''
    if (!isNaN(imgItem)) {
        for (let key in svgInfo) {
            if (key == imgItem) {
                imgPath = `${VI_IMAGES_URL}${svgInfo[key]}`
            }
        }
    } else {
        imgPath = `${VI_IMAGES_URL}${imgItem}`
    }

    return imgPath;
}

export async function verifyGeoCookie(cookie, vId){
    let cookieVal = decryptVal(cookie) || "";
    let validateCookie = await new Promise((resolve, reject)=>{
        if(Object.keys(navigator.geolocation).length > 0){
            navigator.geolocation.getCurrentPosition(async position => {
                let lat = position.coords.latitude;
                let lng = position.coords.longitude;
                resolve({lat, lng});
            });
        } else{
            resolve({});
        }
    });
    let verificationStr = `${validateCookie?.lat},${validateCookie?.lng},${vId}`;
    if(cookieVal == verificationStr){
        return true
    }
}