import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import adminPaymentServices from '../components/services/adminPaymentServices.js';


export const createDiscountCouponCode = createAsyncThunk('adminPaymentInfo/adminCreateDiscountCouponCode',async ({ qprm }) => {
    try { 
        const response = await adminPaymentServices.adminCreateDiscountCouponCode({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
); 
export const getOneDiscountCouponCode = createAsyncThunk('adminPaymentInfo/adminGetOneDiscountCouponCode',async ({ qprm }) => {
    try { 
        const response = await adminPaymentServices.adminGetOneDiscountCouponCode({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
); 

export const checkDiscountCodeUniqueness = createAsyncThunk('adminPaymentInfo/checkDiscountCodeUniqueness', async ({ qprm }) => {
    try {
        const response = await adminPaymentServices.adminCheckUniquenessOfDiscountCode({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const deleteDiscountCouponCode = createAsyncThunk('adminPaymentInfo/deleteDiscountCouponCode', async ({ qprm }) => {
    try {
        const response = await adminPaymentServices.adminDeleteDiscountCouponCode({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const getUserMediaContentAccessList = createAsyncThunk('adminPaymentInfo/getUserMediaContentAccessList', async ({ qprm }) => {
    try {
        const response = await adminPaymentServices.adminGetUserMediaContentAccessList({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const adminPaymentSlice = createSlice({
    name:'adminPaymentInfo',
    initialState: {
        loading: false,
        error: null,
        data: [],
        submitDiscount:false
    },
    reducers: {},
    extraReducers: {
        [createDiscountCouponCode.pending]: (state) => {
            state.submitDiscount = true;
        },
        [createDiscountCouponCode.rejected]: (state, action) => {
            state.submitDiscount = false;
        },
        [createDiscountCouponCode.fulfilled]: (state, action) => {
            state.submitDiscount = false;
            state.data = action.payload.data;
        },
        [getOneDiscountCouponCode.pending]: (state) => {
            state.loading = true;
        },
        [getOneDiscountCouponCode.rejected]: (state, action) => {
            state.loading = false;
        },
        [getOneDiscountCouponCode.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [checkDiscountCodeUniqueness.pending] :(state) => {
            state.loading = true;
        },
        [checkDiscountCodeUniqueness.rejected] :(state) =>{
            state.loading = false;
        },
        [checkDiscountCodeUniqueness.fulfilled] :(state,action) =>{
            state.loading = false;
            state.data = action.payload.data;
        },
        [deleteDiscountCouponCode.pending] :(state) => {
            state.loading = true;
        },
        [deleteDiscountCouponCode.rejected] :(state) =>{
            state.loading = false;
        },
        [deleteDiscountCouponCode.fulfilled] :(state,action) =>{
            state.loading = false;
            state.data = action.payload.data;
        },
        [getUserMediaContentAccessList.pending] :(state) => {
            state.loading = true;
        },
        [getUserMediaContentAccessList.rejected] :(state) =>{
            state.loading = false;
        },
        [getUserMediaContentAccessList.fulfilled] :(state,action) =>{
            state.loading = false;
            state.data = action.payload.data;
        }
    }
})


export default adminPaymentSlice.reducer;
