import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import adminContentBlockService from "../components/services/adminContentBlockService";

export const adminContentBlockList = createAsyncThunk(
    "adminContentBlock/adminContentBlockList",
    async ({ qprm }) => {
        try {
            const response = await adminContentBlockService.adminContentBlockList({
                qprm,
            });
            if (
                typeof response !== "undefined" &&
                typeof response.data !== "undefined"
            ) {
                const data = await response.data;
                return data;
            }
        } catch (e) {
            return e.response.data;
        }
    }
);

export const adminCreateContentBlock = createAsyncThunk(
    "adminContentBlock/adminCreateContentBlocks",
    async ({ qprm }) => {
        try {
            const response = await adminContentBlockService.adminCreateContentBlock({
                qprm,
            });
            if (
                typeof response !== "undefined" &&
                typeof response.data !== "undefined"
            ) {
                const data = await response.data;
                return data;
            }
        } catch (e) {
            return e.response.data;
        }
    }
)

export const adminGetContentBlockInfo = createAsyncThunk(
    "adminContentBlock/adminGetContentBlockInfo",
    async ({ qprm }) => {
        try {
            const response = await adminContentBlockService.adminGetContentBlockInfo({
                qprm,
            });
            if (
                typeof response !== "undefined" &&
                typeof response.data !== "undefined"
            ) {
                const data = await response.data;
                return data;
            }
        } catch (e) {
            return e.response.data;
        }
    }
)

export const adminDeleteContentBlock = createAsyncThunk(
    "adminContentBlock/adminDeleteContentBlock",
    async ({ qprm }) => {
        try {
            const response = await adminContentBlockService.adminDeleteContentBlock({
                qprm,
            });
            if (
                typeof response !== "undefined" &&
                typeof response.data !== "undefined"
            ) {
                const data = await response.data;
                return data;
            }
        } catch (e) {
            return e.response.data;
        }
    }
)

export const adminContentBlockSlice = createSlice({
    name: "adminContentBlock",
    initialState: {
        loading: false,
        error: null,
        data: [],
        submitContentBlock: false
    },
    reducers: {},
    extraReducers: {
        [adminContentBlockList.pending]: (state) => {
            state.loading = true;
        },
        [adminContentBlockList.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminContentBlockList.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminCreateContentBlock.pending]: (state) => {
            state.submitContentBlock = true;
        },
        [adminCreateContentBlock.rejected]: (state, action) => {
            state.submitContentBlock = false;
        },
        [adminCreateContentBlock.fulfilled]: (state, action) => {
            state.submitContentBlock = false;
            state.data = action.payload.data;
        },
        [adminGetContentBlockInfo.pending]: (state) => {
            state.loading = true;
        },
        [adminGetContentBlockInfo.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminGetContentBlockInfo.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminDeleteContentBlock.pending]: (state) => {
            state.loading = true;
        },
        [adminDeleteContentBlock.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminDeleteContentBlock.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        }
    }
})

export default adminContentBlockSlice.reducer;