import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import adminContentPackService from '../components/services/adminContentPackService.js';


export const adminGetOneContentPackInfo = createAsyncThunk('adminContentPackInfo/adminGetOneContentPackInfo', async ({ qprm }) => {
    try {
        const response = await adminContentPackService.adminGetOneContentPackInfo({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const adminCreateContentPacks = createAsyncThunk('adminContentPackInfo/adminCreateContentPacks', async ({ qprm }) => {
    try {
        const response = await adminContentPackService.adminCreateContentPacks({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const adminDeleteContentPack = createAsyncThunk('adminContentPackInfo/adminDeleteContentPack', async ({ qprm }) => {
    try {
        const response = await adminContentPackService.adminDeleteContentPack({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const adminGetDomainContentPack = createAsyncThunk('adminContentPackInfo/adminGetDomainContentPack', async ({ qprm }) => {
    try {
        const response = await adminContentPackService.adminGetDomainContentPack({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);




export const adminContentPackSlice = createSlice({
    name: 'adminContentPackInfo',
    initialState: {
        loading: false,
        error: null,
        data: [],
        createContentPack: false
    },
    reducers: {},
    extraReducers: {
        [adminGetOneContentPackInfo.pending]: (state) => {
            state.loading = true;
        },
        [adminGetOneContentPackInfo.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminGetOneContentPackInfo.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminCreateContentPacks.pending]: (state) => {
            state.createContentPack = true;
        },
        [adminCreateContentPacks.rejected]: (state, action) => {
            state.createContentPack = false;
        },
        [adminCreateContentPacks.fulfilled]: (state, action) => {
            state.createContentPack = false;
            state.data = action.payload.data;
        },
        [adminDeleteContentPack.pending]: (state) => {
            state.loading = true;
        },
        [adminDeleteContentPack.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminDeleteContentPack.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminGetDomainContentPack.pending]: (state) => {
            state.loading = true;
        },
        [adminGetDomainContentPack.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminGetDomainContentPack.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
    }
})


export default adminContentPackSlice.reducer;
