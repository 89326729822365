import { apiConstants } from "../../constants/api.constants"
import ApiService from "./ApiServices"

export default class contentOrdersService {
    static getCustomPublicationCostOfMediaItems = ({ qprm }) => {
        return ApiService.post(apiConstants.API_TO_GET_CUSTOM_PUBLICATION_COST_MEDIA_ITEMS, { qprm })
    }

    static getStripeSalesTax = ({qprm}) =>{
        return ApiService.post(apiConstants.API_TO_GET_SALES_TAX, { qprm })
    }

    static getContentOrderSubmit = ( {qprm}) =>{
        return ApiService.post(apiConstants.API_TO_PLACE_ORDER, { qprm })
    }

    static getPayByLinkUrl = ( {qprm}) =>{
        return ApiService.post(apiConstants.API_TO_GET_PAY_LINK, { qprm })
    }
    
    static frontCustomerCreateStripeUsingPId = ({ qprm }) => {
        return ApiService.post(apiConstants.API_CREATE_CUSTOMER_STRIPE_USING_P_ID, { qprm })
    }

    static frontPageTopContentGather = ({ qprm }) => {
        return ApiService.post(apiConstants.API_GET_DOMAIN_CONTENT, { qprm })
    }

    static commonNewsLetterDataStoreReducer = ({ qprm }) => {
        return ApiService.post(apiConstants.API_SAVE_USER_INFO_SELL_MAILCHIMP, { qprm })
    }

    static frontPlaceOrderForDigitalPublication = ({ qprm }) => {
        return ApiService.post(apiConstants.DEBITCARD_PAYMENT, { qprm })
    }

    static frontGetMediaCategoriesToListOnTheBasisOfType = ({ qprm }) => {
        return ApiService.post(apiConstants.API_GET_MEDIA_CATEGORIES, { qprm })
    }

    static frontGetDomainMediaContents = ({ qprm }) => {
        return ApiService.post(apiConstants.API_GET_DOMAIN_CONTENT, { qprm })
    }

    static frontGetSpecificMediaContentInfo = ({ qprm }) => {
        return ApiService.post(apiConstants.API_GET_MEDIA_CONTENT_DETAIL, { qprm })
    }
}