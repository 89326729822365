import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import adminContentServices from '../components/services/adminContentServices';


export const adminMediaCategoryList = createAsyncThunk('adminDomainInfo/adminMediaCategoryList',async ({ qprm }) => {
    try {
        
        const response = await adminContentServices.adminMediaCategoryList({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
); 

export const adminCreateMediaCategory = createAsyncThunk('adminDomainInfo/adminCreateMediaCategory',async ({ qprm }) => {
    try {
        const response = await adminContentServices.adminCreateMediaCategory({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const adminRemoveMediaCategory = createAsyncThunk('adminDomainInfo/adminRemoveMediaCategory',async ({ qprm }) => {
    try {
        
        const response = await adminContentServices.adminRemoveMediaCategory({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const adminAssignDomainContent = createAsyncThunk("adminContentCategory/adminAssignDomainContent", async ({ qprm }) => {
    try {
        const response = await adminContentServices.assignDomainContent({ qprm });
        if (typeof response !== "undefined" && typeof response.data !== "undefined") {
            const data = await response.data;
            return data;
        }
    } catch (error) {
        return error.response.data
    }
})

export const adminCategoryList = createAsyncThunk("adminContentCategory/adminCategoryList", async () => {
    try {
        const response = await adminContentServices.getCategoryList();
        if (typeof response !== "undefined" && typeof response.data !== "undefined") {
            const data = await response.data;
            return data;
        }
    } catch (error) {
        return error.response.data
    }
})


export const adminLanguageList = createAsyncThunk("adminContentCategory/adminLanguageList", async () => {
    try {
        const response = await adminContentServices.getLangList();
        if (typeof response !== "undefined" && typeof response.data !== "undefined") {
            const data = await response.data;
            return data;
        }
    } catch (error) {
        return error.response.data
    }
})

export const adminExisAndNewMContent = createAsyncThunk("adminContentCategory/adminExisAndNewMContent", async ({ qprm }) => {
    try {

        const response = await adminContentServices.getExistingAndNewMediaContent({ qprm });
        if (typeof response !== "undefined" && typeof response.data !== "undefined") {
            const data = await response.data;
            return data;
        }
    } catch (error) {
        return error.response.data
    }
})
export const adminRemoveDomainContent = createAsyncThunk("adminContentCategory/adminRemoveDomainContent", async ({ qprm }) => {
    try {
        const response = await adminContentServices.removeDomainContent({ qprm });
        if (typeof response !== "undefined" && typeof response.data !== "undefined") {
            const data = await response.data;
            return data;
        }
    } catch (error) {
        return error.response.data
    }
})


export const adminUserSlice = createSlice({
    name:'adminDomainInfo',
    initialState: {
        loading: false,
        error: null,
        data: [],
        submitMediaCategory:false
    },
    reducers: {},
    extraReducers: {
        [adminMediaCategoryList.pending]: (state) => {
            state.loading = true;
        },
        [adminMediaCategoryList.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminMediaCategoryList.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminCreateMediaCategory.pending]: (state) => {
            state.submitMediaCategory = true;
        },
        [adminCreateMediaCategory.rejected]: (state, action) => {
            state.submitMediaCategory = false;
        },
        [adminCreateMediaCategory.fulfilled]: (state, action) => {
            state.submitMediaCategory = false;
            state.data = action.payload.data;
        },
        [adminRemoveMediaCategory.pending]: (state) => {
            state.loading = true;
        },
        [adminRemoveMediaCategory.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminRemoveMediaCategory.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminAssignDomainContent.pending]: (state) => {
            state.loading = true;
        },
        [adminAssignDomainContent.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminAssignDomainContent.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminCategoryList.pending]: (state) => {
            state.loading = true;
        },
        [adminCategoryList.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminCategoryList.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminLanguageList.pending]: (state) => {
            state.loading = true;
        },
        [adminLanguageList.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminLanguageList.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminExisAndNewMContent.pending]: (state) => {
            state.loading = true;
        },
        [adminExisAndNewMContent.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminExisAndNewMContent.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminRemoveDomainContent.pending]: (state) => {
            state.loading = true;
        },
        [adminRemoveDomainContent.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminRemoveDomainContent.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
    }
})


export default adminUserSlice.reducer;
