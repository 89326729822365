import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { decryptIt } from "../components/common/commonHelper";
import adminUserServices from "../components/services/adminUserServices";

export const getDomainList = createAsyncThunk(
  "adminDomainInfo/getAdministrationList", async ({ qprm }) => {
    try {
      const response = await adminUserServices.getDomainList({ qprm })
      if (
        typeof response !== "undefined" &&
        typeof response.data !== "undefined"
      ) {
        return response;
      }
    } catch (e) {
      return e.response.data
    }
  }
)

export const authDataRequest = createAsyncThunk(
  "adminDomainInfo/authDataRequest", async ({ qprm }) => {
    try {
      const response = await adminUserServices.authDataRequest({ qprm });
      if (
        typeof response !== "undefined" &&
        typeof response.data !== "undefined"
      ) {
        const data = await response.data;
        return data;
      }
    } catch (error) {
      return error.response
    }
  }
)

export const adminValidateUserInfo = createAsyncThunk(
  "adminDomainInfo/adminValidateUserInfo",
  async ({ qprm }) => {
    try {
      const response = await adminUserServices.adminUserLogin({ qprm });
      if (
        typeof response !== "undefined" &&
        typeof response.data !== "undefined"
      ) {
        const data = await response.data;
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const adminForgetPassword = createAsyncThunk(
  "adminDomainInfo/adminForgetPassword",
  async ({ qprm }) => {
    try {
      const response = await adminUserServices.adminForgetPassword({ qprm });
      if (
        typeof response !== "undefined" &&
        typeof response.data !== "undefined"
      ) {
        const data = await response.data;
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const adminResetPassword = createAsyncThunk(
  "adminDomainInfo/adminResetPassword",
  async ({ qprm }) => {
    try {
      const response = await adminUserServices.adminResetPassword({ qprm });
      if (
        typeof response !== "undefined" &&
        typeof response.data !== "undefined"
      ) {
        const data = await response.data;
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const getAllRoleList = createAsyncThunk(
  "adminDomainInfo/getAllRoleList",
  async ({ qprm }) => {
    try {
      const response = await adminUserServices.adminGetAllRolesList({ qprm });
      if (
        typeof response !== "undefined" &&
        typeof response.data !== "undefined"
      ) {
        const data = await response.data;
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const adminCreateRole = createAsyncThunk(
  "adminDomainInfo/adminCreateRole",
  async ({ qprm }) => {
    try {
      const response = await adminUserServices.adminCreateRole({ qprm });
      if (
        typeof response !== "undefined" &&
        typeof response.data !== "undefined"
      ) {
        const data = await response.data;
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const adminUpdateRole = createAsyncThunk(
  "adminDomainInfo/adminUpdateRole",
  async ({ qprm }) => {
    try {
      const response = await adminUserServices.adminUpdateRole({ qprm });
      if (
        typeof response !== "undefined" &&
        typeof response.data !== "undefined"
      ) {
        const data = await response.data;
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const adminGetRoleInfoFromUid = createAsyncThunk(
  "adminDomainInfo/adminGetRoleInfoFromUid",
  async ({ qprm }) => {
    try {
      const response = await adminUserServices.adminGetRoleInfoFromUid({
        qprm,
      });
      if (typeof response.data !== "undefined" && response.data.status === 1) {
        let decryptedDt = decryptIt(response.data.data);
        decryptedDt = decryptedDt ? decryptedDt.slice(1, -1) : "";
        decryptedDt = JSON.parse(decryptedDt);
        return decryptedDt;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const adminRemoveRole = createAsyncThunk(
  "adminDomainInfo/adminRemoveRole",
  async ({ qprm }) => {
    try {
      const response = await adminUserServices.adminRemoveRole({ qprm });
      if (
        typeof response !== "undefined" &&
        typeof response.data !== "undefined"
      ) {
        const data = await response.data;
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const adminCreateUser = createAsyncThunk(
  "adminDomainInfo/adminCreateUser",
  async ({ qprm }) => {
    try {
      const response = await adminUserServices.adminCreateUser({ qprm });
      if (
        typeof response !== "undefined" &&
        typeof response.data !== "undefined"
      ) {
        const data = await response.data;
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const adminUpdateUser = createAsyncThunk(
  "adminDomainInfo/adminUpdateUser",
  async ({ qprm }) => {
    try {
      const response = await adminUserServices.adminUpdateUser({ qprm });
      if (
        typeof response !== "undefined" &&
        typeof response.data !== "undefined"
      ) {
        const data = await response.data;
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const adminGetUserInfoFromUid = createAsyncThunk(
  "adminDomainInfo/adminGetUserInfoFromUid",
  async ({ qprm }) => {
    try {
      const response = await adminUserServices.adminGetUserInfoFromUid({
        qprm,
      });
      if (typeof response.data !== "undefined" && response.data.status === 1) {
        let decryptedDt = decryptIt(response.data.data);
        decryptedDt = decryptedDt ? decryptedDt.slice(1, -1) : "";
        decryptedDt = JSON.parse(decryptedDt);
        return decryptedDt;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const adminRemoveUser = createAsyncThunk(
  "adminDomainInfo/adminRemoveUser",
  async ({ qprm }) => {
    try {
      const response = await adminUserServices.adminRemoveUser({ qprm });
      if (
        typeof response !== "undefined" &&
        typeof response.data !== "undefined"
      ) {
        const data = await response.data;
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const adminCheckUserEmail = createAsyncThunk(
  "adminDomainInfo/adminCheckUserEmail",
  async ({ qprm }) => {
    try {
      const response = await adminUserServices.adminCheckUserEmail({ qprm });
      if (
        typeof response !== "undefined" &&
        typeof response.data !== "undefined"
      ) {
        const data = await response.data;
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const adminDomainAndMContentList = createAsyncThunk(
  "adminDomainInfo/adminDomainAndMContentList",
  async () => {
    try {
      const response = await adminUserServices.adminDomainAndMContentList();
      if (
        typeof response !== "undefined" &&
        typeof response.data !== "undefined"
      ) {
        const data = await response.data;
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const adminGetUniqueUserInfo = createAsyncThunk(
  "adminDomainInfo/adminGetUniqueUserInfo",
  async ({ qprm }) => {
    try {
      const response = await adminUserServices.adminGetUniqueUserInfo({ qprm });
      if (
        typeof response !== "undefined" &&
        typeof response.data !== "undefined"
      ) {
        const data = await response.data;
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);


export const adminGetPermissionList = createAsyncThunk(
  "adminDomainInfo/adminGetPermissionList",
  async () => {
    try {
      const response = await adminUserServices.adminGetPermissionList();
      if (
        typeof response !== "undefined" &&
        typeof response.data !== "undefined"
      ) {
        const data = await response.data;
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const adminUserSlice = createSlice({
  name: "adminUser",
  initialState: {
    loading: false,
    error: null,
    data: [],
    userInfo: "",
    userData: {},
    roleData: {},
    domainData: {},
    adminUserId: "",
    notFound: false,
    permissionList: [],
    userPermissions: [],
    isAdmin: false
  },
  reducers: {},
  extraReducers: {
    [adminGetUserInfoFromUid.pending]: (state) => {
      state.loading = true;
    },
    [adminGetUserInfoFromUid.rejected]: (state, action) => {
      state.loading = false;
    },
    [adminGetUserInfoFromUid.fulfilled]: (state, action) => {
      state.loading = false;
      state.userData = action.payload;
    },

    [adminGetRoleInfoFromUid.pending]: (state) => {
      state.loading = true;
    },
    [adminGetRoleInfoFromUid.rejected]: (state, action) => {
      state.loading = false;
    },
    [adminGetRoleInfoFromUid.fulfilled]: (state, action) => {
      state.loading = false;
      state.roleData = action.payload;
    },
    [adminValidateUserInfo.pending]: (state) => {
      state.loading = true;
    },
    [adminValidateUserInfo.rejected]: (state, action) => {
      state.loading = false;
    },
    [adminValidateUserInfo.fulfilled]: (state, action) => {
      state.loading = false;
      state.userInfo = action.payload.data;
    },
    [adminForgetPassword.pending]: (state) => {
      state.loading = true;
    },
    [adminForgetPassword.rejected]: (state, action) => {
      state.loading = false;
    },
    [adminForgetPassword.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    [adminResetPassword.pending]: (state) => {
      state.loading = true;
    },
    [adminResetPassword.rejected]: (state, action) => {
      state.loading = false;
    },
    [adminResetPassword.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    [adminCreateUser.pending]: (state) => {
      state.loading = true;
    },
    [adminCreateUser.rejected]: (state, action) => {
      state.loading = false;
    },
    [adminCreateUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    [adminUpdateUser.pending]: (state) => {
      state.loading = true;
    },
    [adminUpdateUser.rejected]: (state, action) => {
      state.loading = false;
    },
    [adminUpdateUser.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [adminCreateRole.pending]: (state) => {
      state.loading = true;
    },
    [adminCreateRole.rejected]: (state, action) => {
      state.loading = false;
    },
    [adminCreateRole.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [adminUpdateRole.pending]: (state) => {
      state.loading = true;
    },
    [adminUpdateRole.rejected]: (state, action) => {
      state.loading = false;
    },
    [adminUpdateRole.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [adminCheckUserEmail.pending]: (state) => {
      state.loading = true;
    },
    [adminCheckUserEmail.rejected]: (state, action) => {
      state.loading = false;
    },
    [adminCheckUserEmail.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    [adminDomainAndMContentList.pending]: (state) => {
      state.loading = true;
    },
    [adminDomainAndMContentList.rejected]: (state, action) => {
      state.loading = false;
    },
    [adminDomainAndMContentList.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    [getDomainList.pending]: (state) => {
      state.loading = true;
    },
    [getDomainList.rejected]: (state, action) => {
      state.loading = false;
    },
    [getDomainList.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      if (action?.payload?.data && action?.payload?.data?.status == 1) {
        state.domainData = JSON.parse(decryptIt(action?.payload?.data?.data)?.slice(1, -1));
      } else {
        state.notFound = action?.payload?.data?.message || true
      }
    },
    [authDataRequest.pending]: (state) => {
      state.loading = true;
    },
    [authDataRequest.rejected]: (state, action) => {
      state.loading = false;
    },
    [authDataRequest.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    [adminGetUniqueUserInfo.pending]: (state) => {
      state.loading = true;
    },
    [adminGetUniqueUserInfo.rejected]: (state, action) => {
      state.loading = false;
    },
    [adminGetUniqueUserInfo.fulfilled]: (state, action) => {
      console.log(action?.payload, "action?.payload")
      if (action?.payload?.status && action?.payload?.data) {
        let user = JSON.parse(decryptIt(action?.payload?.data)?.slice(1, -1))
        console.log(user, "user")
        let roles = user?.roles_details
        state.loading = false;
        state.data = action.payload.data;
        state.adminUserId = user?._id;
        state.userInfo = user;
        state.userPermissions = user?.permissions || []
        const isAdminRolePresent = (roles) => {
          return roles.some(role => role.roleName === "Administrator");
        };
        state.isAdmin = isAdminRolePresent(roles)
      }
    },
    [adminGetPermissionList.pending]: (state) => {
      state.loading = true;
    },
    [adminGetPermissionList.rejected]: (state, action) => {
      state.loading = false;
    },
    [adminGetPermissionList.fulfilled]: (state, action) => {
      if (action?.payload?.data && action?.payload?.status) {
        let permissions = JSON.parse(decryptIt(action?.payload?.data)?.slice(1, -1))
        console.log(permissions, "permissions")
        state.permissionList = permissions
        state.loading = false;
      }
    },
  },
});

export default adminUserSlice.reducer;

