import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import compendiumService from '../components/services/compendiumService';

export const compendium = createAsyncThunk('compendiumInfo/compendium', async ({ qprm }) => {
    try {
        const response = await compendiumService.compendium({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const getAllCompendiumTitle = createAsyncThunk('compendiumInfo/getAllCompendiumTitle', async () => {
    try {
        const response = await compendiumService.getAllCompendiumTitle();
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const createCompendium = createAsyncThunk('compendiumInfo/createCompendium', async () => {
    try {
        const response = await compendiumService.createCompendium();
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data
            return data;
        }
    } catch (e) {
        return e.response.data
    }
})

export const getCompendiumInfoFromId = createAsyncThunk('compendiumInfo/getCompendiumInfo', async ({ qprm }) => {
    try {
        const response = await compendiumService.getCompendiumInfoFromId({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data
            return data;
        }
    } catch (e) {
        return e.response.data
    }
});

export const getOneCompendiumListing = createAsyncThunk('compendiumInfo/getOneCompendiumListing', async ({ qprm }) => {
    try {
        const response = await compendiumService.getOneCompendiumListing({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const deleteCompendiumInfo = createAsyncThunk('compendiumInfo/deleteCompendiumInfo', async ({ qprm }) => {
    try {
        const response = await compendiumService.deletCompendiumInfo({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data
    }
})

export const getDomainCompendium = createAsyncThunk('compendiumInfo/getDomainCompendium', async ({ qprm }) => {
    try {
        const response = await compendiumService.getDomainCompendium({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const compendiumSlice = createSlice({
    name: 'compendiumInfo',
    initialState: {
        loading: false,
        error: null,
        data: [],
    },
    reducers: {},
    extraReducers: {
        [compendium.pending]: (state) => {
            state.loading = true;
        },
        [compendium.rejected]: (state, action) => {
            state.loading = false;
        },
        [compendium.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [getAllCompendiumTitle.pending]: (state) => {
            state.loading = true;
        },
        [getAllCompendiumTitle.rejected]: (state, action) => {
            state.loading = false;
        },
        [getAllCompendiumTitle.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [createCompendium.pending]: (state) => {
            state.loading = true;
        },
        [createCompendium.rejected]: (state) => {
            state.loading = false;
        },
        [createCompendium.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [getCompendiumInfoFromId.pending]: (state) => {
            state.loading = true;
        },
        [getCompendiumInfoFromId.rejected]: (state) => {
            state.loading = false;
        },
        [getCompendiumInfoFromId.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data
        },
        [getOneCompendiumListing.pending]: (state) => {
            state.loading = true;
        },
        [getOneCompendiumListing.rejected]: (state, action) => {
            state.loading = false;
        },
        [getOneCompendiumListing.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [deleteCompendiumInfo.pending]: (state) => {
            state.loading = true;
        },
        [deleteCompendiumInfo.rejected]: (state) => {
            state.loading = false;
        },
        [deleteCompendiumInfo.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data
        },
        [getDomainCompendium.pending]: (state) => {
            state.loading = true;
        },
        [getDomainCompendium.rejected]: (state) => {
            state.loading = false;
        },
        [getDomainCompendium.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data
        }

    }
})


export default compendiumSlice.reducer;