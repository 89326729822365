import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Loader from './components/front/Loader';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store/store';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import MultiStoreProvider from './MultiStoreProvider';
import adminStore from './redux/store';
window.skeletonLoaderTiming = 2000;


ReactDOM.render(
	<I18nextProvider i18n={i18n} >
		<BrowserRouter>
			<MultiStoreProvider stores={[store, adminStore]}><App /></MultiStoreProvider>
		</BrowserRouter>
	</I18nextProvider>,
	document.getElementById('root')
)