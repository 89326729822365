import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

class MultiStoreProvider extends React.Component {
  render() {
    const { stores, children } = this.props;

    // Wrap the children with the providers for each store
    let nestedProviders = children;
    for (let i = stores.length - 1; i >= 0; i--) {
      const store = stores[i];
      nestedProviders = (
        <Provider store={store}>
          {nestedProviders}
        </Provider>
      );
    }

    return nestedProviders;
  }
}

MultiStoreProvider.propTypes = {
  stores: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.node.isRequired,
};

export default MultiStoreProvider;
