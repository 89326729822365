import { apiConstants } from '../../constants/api.constants';
import ApiService from './ApiServices';

export default class adminLangServices {

  static adminUidLangInfo = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_UID_LANG_INFO, { qprm: qprm });
  };

  static adminRemoveLangInfo = ({ qprm }) => {
    return ApiService.post(apiConstants.API_REMOVE_LANG_INFO, { qprm: qprm });
  };

  static adminLangInfoUpdate = ({ qprm }) => {
    return ApiService.patch(apiConstants.API_UPDATE_DEFAULT_LANG_INFO, { qprm: qprm });
  };

  static adminCreateLangInfo = ({ qprm }) => {
    return ApiService.post(apiConstants.API_CREATE_LANG_INFO, { qprm: qprm });
  };

  static adminUpdateLangInfo = ({ qprm }) => {
    return ApiService.post(apiConstants.API_UPDATE_LANG_INFO, { qprm: qprm });
  };

  static adminLangStatusUpdate = ({ qprm }) => {
    return ApiService.patch(apiConstants.API_UPDATE_LANG_STATUS, { qprm: qprm });
  };

  static adminDaomainTransText = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_DOMAIN_TRANS_TEXT, { qprm: qprm });
  };

  static adminUpdateTransText = ({ qprm }) => {
    return ApiService.post(apiConstants.API_UPDATE_TRANS_TEXT, { qprm: qprm });
  };

  static adminGetLangList = () => {
    return ApiService.post(apiConstants.API_POST_LANG_LIST, {});
  };

  static adminCreateLangInfoReact = ({ qprm }) => {
    return ApiService.post(apiConstants.API_CREATE_LANG_INFO_REACT, { qprm: qprm });
  }

  static adminGetTextLangs = () => {
    return ApiService.post(apiConstants.API_GET_TRANSLATED_TEXT, { });
  }

  static adminGetSpecificLangText = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_DOMAIN_TRANS_TEXT, { qprm: qprm });
  }

  static adminCreateLangText = ({ qprm }) => {
    return ApiService.post(apiConstants.API_UPDATE_TRANS_TEXT, { qprm: qprm });
  }

  static adminDeleteLangText = ( {qprm} ) => {
    return ApiService.post(apiConstants.API_DELETE_LANG_TEXT, { qprm: qprm });
  }
  
}
