import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import flightStatusService from '../components/services/flightStatusService';

export const flightStatus = createAsyncThunk('flightStatusInfo/flightStatus',async ({ qprm }) => {
    try { 
        const response = await flightStatusService.flightStatus({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
); 

export const flightStatusSlice = createSlice({
    name:'flightStatusInfo',
    initialState: {
        loading: false,
        error: null,
        data: [],
    },
    reducers: {},
    extraReducers: {
        [flightStatus.pending]: (state) => {
            state.loading = true;
        },
        [flightStatus.rejected]: (state, action) => {
            state.loading = false;
        },
        [flightStatus.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
    }
})


export default flightStatusSlice.reducer;
