import React from 'react'
import Skeleton ,{ SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { getVIInfo } from '../common/commonHelper';

const SkeletonLoader = ({ Scount, SclassName, Scircle, ScontainerClassName, STbaseColor, SThighlightColor, STwidth, STheight, STborderRadius, STduration, STdirection, STinline }) => {
  const vi_settings = getVIInfo();
  
  return (
  <>
  {vi_settings.new_design_popup_magazine_tiles === 1?
  <div className='gradient-wrap'></div>
  :
 <SkeletonTheme baseColor = { STbaseColor } highlightColor = { SThighlightColor }  width = { STwidth }  height = { STheight } borderRadius = { STborderRadius }  duration = { STduration }  direction = { STdirection } inline = {STinline}>
 <Skeleton count = { Scount } className = { typeof vi_settings.enable_skeleton_loader !== "undefined" && vi_settings.enable_skeleton_loader == 1 ? "updated-skeleton" : SclassName } circle = { Scircle } containerClassName = { ScontainerClassName }></Skeleton>
</SkeletonTheme>
  }
   
  </>
  )
}

export default SkeletonLoader
