import { apiConstants } from "../../constants/api.constants";
import ApiService from "./ApiServices";

export default class adminContentPackService {

    static adminGetOneContentPackInfo = ({ qprm }) => {
        return ApiService.post(apiConstants.API_GET_SPECIFIC_CONTENT_PACK_INFO, { qprm: qprm });
    };

    static adminCreateContentPacks = ({ qprm }) => {
        return ApiService.post(apiConstants.API_CREATE_CONTENT_PACK, { qprm });
    };

    static adminDeleteContentPack = ({ qprm }) => {
        return ApiService.post(apiConstants.API_DELETE_ONE_CONTENT_PACK, { qprm });
    };

    static adminGetDomainContentPack = ({ qprm }) => {
        return ApiService.post(apiConstants.API_GET_DOMAIN_CONTENT_PACK, { qprm });
    };

};