import { apiConstants } from '../../constants/api.constants';
import ApiService from './ApiServices';

export default class adminMediaContentServices {

  static adminGetMediaContentList = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_MEDIA_CONTENT_LIST_REACT, { qprm: qprm });
  };

  static adminGetMediaCategoryList = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_MEDIA_CATEGORY_INFO, { qprm });
  };

  static adminGetGroupedMediaContentTypes = () => {
    return ApiService.post(apiConstants.API_GET_GROUPED_MEDIA_CONTENT_TYPES, {});
  }

  static adminGetMediaContentCIDUsingSearch = () => {
    return ApiService.post(apiConstants.API_GET_CID_WITH_SEARCH_TITLE_DISTRIBUTOR, {});
  }

  static adminGetMediaContentFromId = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_MEDIA_CONTENT_LIST_FROM_UID_REACT, { qprm: qprm })
  }
  static adminGetMediaContentTypes = () => {
    return ApiService.post(apiConstants.API_GET_CONTENT_TYPE, {})
  }
  static adminDeleteMediaContemt = ({ mediaObjId }) => {
    return ApiService.post(apiConstants.API_DELETE_MEDIA_CONTENT, { mediaObjId: mediaObjId })
  }
  static adminGetMediaCidFromTitle = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_MEDIA_CID_FROM_TITLE, { qprm: qprm })
  }
  static adminGetMediaContentFromType = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_MEDIA_FROM_TYPE, { qprm: qprm })
  }
  static adminGetMediaTypeFromId = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_MEDIA_TYPE_FROM_ID, { qprm: qprm })
  }
  static adminGetDomainMediaContentCount = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_DOMAIN_MEDIACONTENT_COUNT, { qprm: qprm })
  }
  static adminGetCSVForMediaContent = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_CSV_REPORT_FOR_MEDIA_CONTENT, {})
  }
  static adminGetPremiumContentList = () => {
    return ApiService.post(apiConstants.API_TO_GET_PREMIUM_CONTENT_LISTING, {});
  }

  static adminRemovePremiumContent = ({ qprm }) => {
    return ApiService.post(apiConstants.API_REMOVE_PREMIUM_CONTENT_INFO, { qprm: qprm });
  };
  static adminGetAllMediaContentList = () => {
    return ApiService.post(apiConstants.API_GET_ALL_MEDIA_CONTENT_LIST, {});
  }
}


