import { apiConstants } from '../../constants/api.constants';
import ApiService from './ApiServices';

export default class adminContentBlockService {

    static adminContentBlockList = ({ qprm }) => {
        return ApiService.post(apiConstants.API_GET_CONTENT_BLOCK_LIST, { qprm: qprm });
    };
    static adminCreateContentBlock = ({ qprm }) => {
        return ApiService.post(apiConstants.API_CREATE_CONTENT_BLOCK, { qprm: qprm });
    };
    static adminGetContentBlockInfo = ({ qprm }) => {
        return ApiService.post(apiConstants.API_GET_CONTENT_BLOCK_INFO, { qprm: qprm });
    };
    static adminDeleteContentBlock = ({ qprm }) => {
        return ApiService.post(apiConstants.API_DELETE_CONTENT_BLOCK, { qprm: qprm })
    }
}