import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import adminGeoDomainService from '../components/services/adminGeoDomainService';

export const getGeoDomainContentList = createAsyncThunk('adminGeoDomain/getGeoDomainList', async () => {
    try {
        const response = await adminGeoDomainService.adminGetGeoDomainContentList()
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data
        }
    } catch (e) {
        return e.response.data
    }
})
export const adminCreateGeoDomain = createAsyncThunk('adminGeoDomain/createGeoDomain', async ({ qprm }) => {
    try {
        const response = await adminGeoDomainService.adminCreateGeoDomain({ qprm: qprm })
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data
        }
    } catch (e) {
        return e.response.data
    }
})

export const adminGeoDomainInfo = createAsyncThunk('adminGeoDomain/adminGeoDomainInfo', async ({ qprm }) => {
    try {
        const response = await adminGeoDomainService.adminGeoDomainInfo({ qprm: qprm })
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data
        }
    } catch (e) {
        return e.response.data
    }
})

export const adminDeleteGeoDomain = createAsyncThunk('adminGeoDomain/adminDeleteGeoDomain', async ({ qprm }) => {
    try {
        const response = await adminGeoDomainService.adminDeleteGeoDomain({ qprm: qprm })
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data
        }
    } catch (e) {
        return e.response.data
    }
})

export const adminGetGeoDomainMediaContent = createAsyncThunk('adminGeoDomain/adminGetGeoDomainMediaContent', async ({ qprm }) => {
    try {
        const response = await adminGeoDomainService.adminGetGeoDomainMediaContent({ qprm: qprm })
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data
        }
    } catch (e) {
        return e.response.data
    }
})

export const adminGeoDomainSlice = createSlice({
    name: "adminGeoDomain",
    initialState: {
        loading: false,
        error: null,
        data: [],
        submitGeoContentPack: false
    },
    reducers: {},
    extraReducers: {
        [getGeoDomainContentList.pending]: (state) => {
            state.loading = true;
        },
        [getGeoDomainContentList.rejected]: (state, action) => {
            state.loading = false;
        },
        [getGeoDomainContentList.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminCreateGeoDomain.pending]: (state) => {
            state.submitGeoContentPack = true
        },
        [adminCreateGeoDomain.rejected]: (state) => {
            state.submitGeoContentPack = false;;
        },
        [adminCreateGeoDomain.fulfilled]: (state, action) => {
            state.submitGeoContentPack = false;
            state.data = action.payload.data
        },
        [adminGeoDomainInfo.pending]: (state) => {
            state.loading = true
        },
        [adminGeoDomainInfo.rejected]: (state) => {
            state.loading = false;;
        },
        [adminGeoDomainInfo.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data
        },
        [adminDeleteGeoDomain.pending]: (state) => {
            state.loading = true
        },
        [adminDeleteGeoDomain.rejected]: (state) => {
            state.loading = false;;
        },
        [adminDeleteGeoDomain.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data
        },
        [adminGetGeoDomainMediaContent.pending]: (state) => {
            state.loading = true
        },
        [adminGetGeoDomainMediaContent.rejected]: (state) => {
            state.loading = false;;
        },
        [adminGetGeoDomainMediaContent.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data
        }
    }
})

export default adminGeoDomainSlice.reducer