import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import tagServices from "../components/services/tagServices";

export const createTagData = createAsyncThunk(
    "tagInformation/createTag",
    async ({ qprm }) => {
        try {
            const response = await tagServices.createTagData({ qprm });
            if (
                typeof response !== "undefined" &&
                typeof response.data !== "undefined"
            ) {
                return response;
            }
        } catch (error) {
            return error.response.data;
        }
    }
);

export const deleteTagData = createAsyncThunk(
    "tagInformation/deleteTag",
    async ({ qprm }) => {
        try {
            const response = await tagServices.deleteTagData({ qprm });
            if (
                typeof response !== "undefined" &&
                typeof response.data !== "undefined"
            ) {
                return response;
            }
        } catch (error) {
            return error.response.data;
        }
    }
);

export const getTagDataById = createAsyncThunk(
    "tagInformation/singleTagData",
    async ({ qprm }) => {
        try {
            const response = await tagServices.getTagDataById({ qprm });
            if (
                typeof response !== "undefined" &&
                typeof response.data !== "undefined"
            ) {
                return response;
            }
        } catch (error) {
            return error.response.data;
        }
    }
);

export const editTagData = createAsyncThunk(
    "tagInformation/editTag",
    async ({ data, qprm }) => {
        try {
            const response = await tagServices.editTagData({ data, qprm });
            if (
                typeof response !== "undefined" &&
                typeof response.data !== "undefined"
            ) {
                return response;
            }
        } catch (error) {
            return error.response.data;
        }
    }
);

export const getTagData = createAsyncThunk(
    "tagInformation/tagList",
    async ({ qprm }) => {
        try {
            const response = await tagServices.getTagData({ qprm });
            if (
                typeof response !== "undefined" &&
                typeof response.data !== "undefined"
            ) {
                return response;
            }
        } catch (error) {
            return error.response.data;
        }
    }
);

export const adminUserSlice = createSlice({
    name: "tagReducer",
    initialState: {
        loading: false,
        error: null,
        data: [],
    },
    reducers: {},
    extraReducers: {
        [getTagData.pending]: (state) => {
            state.loading = true;
        },
        [getTagData.rejected]: (state, action) => {
            state.loading = false;
        },
        [getTagData.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [createTagData.pending]: (state) => {
            state.loading = true;
        },
        [createTagData.rejected]: (state, action) => {
            state.loading = false;
        },
        [createTagData.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [deleteTagData.pending]: (state) => {
            state.loading = true;
        },
        [deleteTagData.rejected]: (state, action) => {
            state.loading = false;
        },
        [deleteTagData.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [getTagDataById.pending]: (state) => {
            state.loading = true;
        },
        [getTagDataById.rejected]: (state, action) => {
            state.loading = false;
        },
        [getTagDataById.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [editTagData.pending]: (state) => {
            state.loading = true;
        },
        [editTagData.rejected]: (state, action) => {
            state.loading = false;
        },
        [editTagData.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
    },
});

export default adminUserSlice.reducer;

