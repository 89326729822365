import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { decryptIt, encryptIt } from '../components/common/commonHelper';

let localStorageCartData = []
if (typeof localStorage.getItem('cartItem') !== "undefined" && localStorage.getItem('cartItem') !== null && localStorage.getItem('cartItem') !== "") {
    let cartItems = localStorage.getItem('cartItem');
    let decryptCartData = decryptIt(cartItems);
    decryptCartData = (decryptCartData) ? decryptCartData.slice(1, -1) : '';
    decryptCartData = JSON.parse(decryptCartData);
    localStorageCartData = decryptCartData
}
export const frontCartSlice = createSlice({
    name: 'frontCartInfo',
    initialState: {
        loading: false,
        error: null,
        data: [],
        carts: localStorageCartData,
        originalPrice: 0
    },
    reducers: {
        addToCart: (state, action) => {
            const { id, allMediaItems } = action.payload;
            state.allMediaItems = allMediaItems;
            const IteamIndex = state.carts.findIndex((item) => item.id === id);
            let cartItems = localStorage.getItem('cartItem');
            let decryptCartData = decryptIt(cartItems);
            decryptCartData = decryptCartData ? JSON.parse(decryptCartData.slice(1, -1)) : [];

            if (IteamIndex >= 0) {
                state.carts[IteamIndex].cartItemQuantity += 1;

                var totalCost = 0;
                for (let i = 0; i < state.carts.length; i++) {
                    let totalCostForSpecificMedia = (parseFloat(state.carts[i].price).toFixed(2) * state.carts[i].cartItemQuantity).toFixed(2);
                    totalCost += parseFloat(totalCostForSpecificMedia);
                }
                let finalPrice = parseFloat(totalCost).toFixed(2)
                state.originalPrice = finalPrice
            } else {
                const temp = { ...action.payload, cartItemQuantity: 1 }
                if (state.carts.length == 0) {

                    state.carts = [...decryptCartData]

                    const IteamIndex = state.carts.findIndex((item) => item.id === action.payload.id);

                    IteamIndex >= 0 ?
                        state.carts[IteamIndex].cartItemQuantity += 1 :
                        state.carts = [...state.carts, temp];

                    if (IteamIndex >= 0) {
                        var totalCost = 0;
                        for (let i = 0; i < state.carts.length; i++) {
                            let totalCostForSpecificMedia = (parseFloat(state.carts[i].price).toFixed(2) * state.carts[i].cartItemQuantity).toFixed(2);
                            totalCost += parseFloat(totalCostForSpecificMedia);
                        }
                        let finalPrice = parseFloat(totalCost).toFixed(2)
                        state.originalPrice = finalPrice
                    }
                } else {
                    state.carts = [...state.carts, temp];

                }

            }
            let stringified = JSON.stringify(state.carts, null, 2);
            let encResult = encryptIt(stringified, true);
            localStorage.setItem('cartItem', encResult)
        },

        removeToCart: (state, action) => {
            let cartItems = localStorage.getItem('cartItem');
            let decryptCartData = decryptIt(cartItems);
            decryptCartData = decryptCartData ? JSON.parse(decryptCartData.slice(1, -1)) : [];

            if (state.carts.length == 0) {

                state.carts = [...decryptCartData]

                const data = state.carts.filter((ele) => ele.id !== action.payload);

                state.carts = data

            } else {
                const data = state.carts.filter((ele) => ele.id !== action.payload);

                state.carts = data
            }
        },

        removeSingleIteams: (state, action) => {
            const IteamIndex_dec = state.carts.findIndex((item) => item.id === action.payload.id);

            if (state.carts[IteamIndex_dec].cartItemQuantity !== 1) {
                state.carts[IteamIndex_dec].cartItemQuantity -= 1

                let stringified = JSON.stringify(state.carts, null, 2);
                let encResult = encryptIt(stringified, true);
                localStorage.setItem('cartItem', encResult)
            } else {
                let cartItems = localStorage.getItem('cartItem');
                let decryptCartData = decryptIt(cartItems);
                decryptCartData = decryptCartData ? JSON.parse(decryptCartData.slice(1, -1)) : [];

                const data = state.carts.filter((ele) => ele.id !== action.payload.id);

                state.carts = data
                let stringified = JSON.stringify(state.carts, null, 2);
                let encResult = encryptIt(stringified, true);
                localStorage.setItem('cartItem', encResult)

            }

        },

        emptycartIteam: (state, action) => {
            state.carts = []
        }
    }
})
export const { addToCart, removeToCart, removeSingleIteams, emptycartIteam, updateFinalAllData } = frontCartSlice.actions;
export default frontCartSlice.reducer;