import { apiConstants } from '../../constants/api.constants';
import ApiService from './ApiServices';

export default class CompendiumService {

  static compendium = ({ qprm }) => {
    return ApiService.post(apiConstants.API_TO_GET_ONE_DOMAIN_COMPENDIUM, { qprm: qprm });
  };

  static getAllCompendiumTitle = () => {
    return ApiService.get(apiConstants.GETALL_COMPENDIUM_TITLE, {});
  };
  static createCompendium = ({ qprm }) => {
    return ApiService.post(apiConstants.API_CREATE_COMPENDIUM, { qprm: qprm })
  }
  static getCompendiumInfoFromId = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_COMPENDIUM_FROM_ID, { qprm: qprm })
  }
  static getOneCompendiumListing = ({ qprm }) => {
    return ApiService.post(apiConstants.GET_ONE_COMPENDIUM_LIST, { qprm: qprm });
  };
  static deletCompendiumInfo = ({ qprm }) => {
    return ApiService.post(apiConstants.API_DELETE_COMPENDIUM_INFO, { qprm: qprm });
  };
  static getDomainCompendium = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_DOMAINS_COMPENDIUM_LIST, { qprm: qprm })
  }

}