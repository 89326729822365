import { apiConstants } from '../../constants/api.constants';
import ApiService from './ApiServices';

export default class adminDomainServices {

  static adminMediaCategoryList = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_MEDIA_CATEGORY_LIST_FROM_UID, { qprm: qprm });
  };

  static adminCreateMediaCategory = ({ qprm }) => {
    return ApiService.post(apiConstants.API_CREATE_MEDIA_CATEGORY_INFO, { qprm: qprm });
  };

  static adminRemoveMediaCategory = ({ qprm }) => {
    return ApiService.post(apiConstants.API_REMOVE_MEDIA_CATEGORY, { qprm: qprm });
  };
  static assignDomainContent = ({ qprm }) => {
    return ApiService.post(apiConstants.ASSIGN_DOMAIN_CONTENT, { qprm: qprm });
  };

  static getCategoryList = () => {
    return ApiService.post(apiConstants.API_GET_MEDIA_CATEGORY_INFO);
  };

  static getLangList = () => {
    return ApiService.post(apiConstants.API_POST_LANG_LIST);
  };

  static getExistingAndNewMediaContent = ({ qprm }) => {
    return ApiService.post(apiConstants.GET_EXISTING_AND_NEW_MEDIA_CONTENT, { qprm: qprm });
  };

  static removeDomainContent = ({ qprm }) => {
    return ApiService.post(apiConstants.REMOVE_DOMAIN_CONTENT, { qprm: qprm });
  };

}


