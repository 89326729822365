import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-xhr-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
import languages from './lang/lang.json';

let urlLang = window.location.href?.split("://")?.[1]?.split("/")?.[1] || ""
//const lngDetector = new LanguageDetector()/*ref : https://github.com/i18next/react-i18next/issues/325*/
//lngDetector.addDetector({
//    name: 'customDetector',
//    lng: window.location.href.split("/"),
//});
//var languages =['en','de','fr','ar', 'int', 'ur'];
let allLanguages = Object.keys(languages);
i18n
    .use(Backend)
    // .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: (urlLang != "" ? urlLang : (navigator?.language?.indexOf('-') != -1 ? navigator?.language?.split("-")[0] : navigator?.language)),
        defaultLanguage: 'en',
        supportedLngs: allLanguages,
        // fallbackLng: 'en',
        //otherLanguages: languages,
        localePath: 'public/locales',
        /*localeSubpaths: {
            en: 'en',
            de: 'de',
            fr: 'fr',
            ar: 'ar',
            ur: 'ur',
            int : 'ar'
        },*/
        load: 'currentOnly',
        shallowRender: false,
        ns: 'translation',
        defaultNS: 'translation',
        localeExtension: 'json',
        debug: false,
        lowerCaseLng: true,
        //localeStructure: '{{lng}}/{{ns}}',
        allowMultiLoading: false,
        nonExplicitSupportedLngs: true,
        //lng: "int",
        //saveMissing: true, // send not translated keys to endpoint
        //saveMissingTo : 'current',//'current' or 'all'
        whitelist: allLanguages,
        interpolation: {
            escapeValue: false,// react already safes from xss
            formatSeparator: ',',
            //format: (value, format) => (format === 'uppercase' ? value.toUpperCase() : value),
        },
        browserLanguageDetection: true,
        serverLanguageDetection: true,
        ignoreRoutes: ['/static/', '/public/', '/api/', '/home'],
        react: {
            //wait: true,
            useSuspense: true,
            //        bindI18n: 'languageChanged loaded',
            //        bindStore: 'added removed',
            //        nsMode: 'default'
        },
        enableSubpaths: false,
        subpathsOnNonDefaultLanguageOnly: false,
        keySeparator: false, // we do not use keys in form messages.welcome
        wait: process && !process.release,
        detection: {
            //order: ['customDetector' ,'path', 'navigator', 'cookie', 'localStorage', 'subdomain', 'queryString', 'htmlTag'],
            order: ['path', 'navigator', 'cookie', 'localStorage', 'subdomain', 'queryString', 'htmlTag'],
            lookupFromPathIndex: 0,
            checkWhitelist: true,
        },
        //nsSeparator: ':ns:::~',
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
            addPath: "/locales/{{lng}}/{{ns}}.missing.json",
            //crossDomain: false
        },
        useSuspense: false,
    });
// let localLang = ((urlLang != "" && urlLang != "digital-media") ? urlLang : (navigator?.language?.indexOf('-') != -1 ? navigator?.language?.split("-")[0] : navigator?.language))
let localLang = (urlLang != "" ? urlLang : (navigator?.language?.indexOf('-') != -1 ? navigator?.language?.split("-")[0] : navigator?.language))
// console.log(localLang,"localLang")
localStorage.removeItem("globalsearchtext")
localStorage.removeItem("globalsearchlang")
localStorage.removeItem("globalsearchcatg")
// console.log(allLanguages,"allLanguages")
// console.log((allLanguages?.includes(urlLang)),"(allLanguages?.includes(urlLang))")
localStorage.setItem("i18nextLng", ((allLanguages?.includes(urlLang))? urlLang : (navigator?.language?.indexOf('-') != -1 ? navigator?.language?.split("-")[0] : navigator?.language)))
// console.log(i18n, "i18n")
//i18n.on('missingKey', function(lngs, namespace, key, res) {console.log(key)});

export default i18n;
