import { configureStore } from '@reduxjs/toolkit';
import adminUserReducer from './adminUserReducer';
import adminLangReducer from './adminLangReducer';
import adminDomainReducer from './adminDomainReducer';
import adminContentReducer from './adminContentReducer';
import adminPaymentReducer from './adminPaymentReducer';
import adminMediaContentPaymentReducer from './adminMediaContentReducer';
import adminContentBlockReducer from './adminContentBlockReducer';
import frontFeedbackReducer from './frontFeedbackReducer';
import compendiumReducer from './compendiumReducer';
import mediaLinkReducer from './mediaLinkReducer';
import adminContentPackReducer from './adminContentPackReducer';
import adminGeoDomainReducer from './adminGeoDomainReducer';
import frontCartReducer from './frontCartReducer';
import frontContentOrderReducer from './frontContentOrderReducer';
import tagReducer from './tagReducer';
import flightStatusReducer from './flightStatusReducer';
import contentPageReducer from './contentPageReducer';

const store = configureStore({
  reducer: {
    adminUser: adminUserReducer,
    adminLang: adminLangReducer,
    adminDomain: adminDomainReducer,
    adminContent: adminContentReducer,
    adminPayment: adminPaymentReducer,
    adminMediaContent: adminMediaContentPaymentReducer,
    frontFeedback: frontFeedbackReducer,
    compendium: compendiumReducer,
    mediaLink: mediaLinkReducer,
    adminContentPack: adminContentPackReducer,
    adminGeoDomain: adminGeoDomainReducer,
    frontCart: frontCartReducer,
    frontContentOrders: frontContentOrderReducer,
    tag: tagReducer,
    adminContentBlock: adminContentBlockReducer,
    flightStatus: flightStatusReducer,
    contentPage: contentPageReducer
  },
});
export default store;

