import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MediaLinkService from '../components/services/mediaLinkService';

export const mediaLinkThunk = createAsyncThunk('mediaLinkInfo/mediaLink',async ({ qprm }) => {
    try { 
        const response = await MediaLinkService.mediaLink({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
); 

export const mediaLinkSlice = createSlice({
    name:'mediaLinkInfo',
    initialState: {
        loading: false,
        error: null,
        data: [],
    },
    reducers: {},
    extraReducers: {
        [mediaLinkThunk.pending]: (state) => {
            state.loading = true;
        },
        [mediaLinkThunk.rejected]: (state, action) => {
            state.loading = false;
        },
        [mediaLinkThunk.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
    }
})


export default mediaLinkSlice.reducer;
