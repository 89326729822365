import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import adminDomainServices from '../components/services/adminDomainServices';
import { decryptIt } from '../components/common/commonHelper';

export const adminDistributorList = createAsyncThunk('adminDomainInfo/adminDistributorList', async ({ qprm }) => {
    try {

        const response = await adminDomainServices.adminDistributorList({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const adminCreateDistributor = createAsyncThunk('adminDomainInfo/adminCreateDistributor', async ({ qprm }) => {
    try {
        const response = await adminDomainServices.adminCreateDistributor({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const adminRemoveDistributor = createAsyncThunk('adminDomainInfo/adminRemoveDistributor', async ({ qprm }) => {
    try {

        const response = await adminDomainServices.adminRemoveDistributor({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const DomainDistributorList = createAsyncThunk('adminDomainInfo/DomainDistributorList', async ({ qprm }) => {
    try {

        const response = await adminDomainServices.adminDomainDistributorInfo({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const masterSiteList = createAsyncThunk('adminDomainInfo/masterSiteList', async ({ qprm }) => {
    try {

        const response = await adminDomainServices.adminMasterSiteList({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const creatingNewSite = createAsyncThunk('adminDomainInfo/creatingNewSite', async ({ qprm }) => {
    try {

        const response = await adminDomainServices.adminCreateSite({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const getDomainInfo = createAsyncThunk('adminDomainInfo/getDomainInfo', async ({ qprm }) => {
    try {

        const response = await adminDomainServices.adminGetDomainInfo({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const getAllDomainList = createAsyncThunk('adminDomainInfo/getAllDomainList', async ({ qprm }) => {
    try {
        const response = await adminDomainServices.adminGetAllDomainList({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const getDomainMediaContentWithPriceList = createAsyncThunk('adminDomainInfo/getDomainMediaContentWithPriceList', async ({ qprm }) => {
    try {
        const response = await adminDomainServices.adminGetDomainMediaContentWithPriceList({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const updateCustomPublicationCosts = createAsyncThunk('adminDomainInfo/updateCustomPublicationCosts', async ({ qprm }) => {
    try {
        const response = await adminDomainServices.adminUpdateCustomPublicationCosts({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const getDomainMediaContentIdsBasedOnType = createAsyncThunk('adminDomainInfo/getDomainMediaContentIdsBasedOnType', async ({ qprm }) => {
    try {
        const response = await adminDomainServices.adminGetDomainMediaContentIdsBasedOnType({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const assignContentIdsToDomain = createAsyncThunk('adminDomainInfo/assignContentIdsToDomain', async ({ qprm }) => {
    try {
        const response = await adminDomainServices.adminAssignContentIdsToDomain({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const getGeoDomainContentPackList = createAsyncThunk('adminDomainInfo/getGeoDomainContentPackList', async () => {
    try {
        const response = await adminDomainServices.adminGetGeoDomainContentPackList();
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const getSpecificGeoDomainPackInfo = createAsyncThunk('adminDomainInfo/getSpecificGeoDomainPackInfo', async ({ qprm }) => {
    try {
        const response = await adminDomainServices.adminGetSpecificGeoDomainPackInfo({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);


export const getSpecificDomainContentBlocks = createAsyncThunk('adminDomainInfo/getSpecificGeoDomainPackInfo', async ({ qprm }) => {
    try {
        const response = await adminDomainServices.adminDomainSpecificContentBlocks({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const updateDomainBlocksOrder = createAsyncThunk('adminDomainInfo/updateDomainBlocksOrder', async ({ qprm }) => {
    try {
        const response = await adminDomainServices.adminUpdateDomainBlocksOrder({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const assignGeoDomainContents = createAsyncThunk('adminDomainInfo/assignGeoDomainContents', async ({ qprm }) => {
    try {
        const response = await adminDomainServices.adminAssignGeoDomainContents({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);


export const getContentPackList = createAsyncThunk('adminDomainInfo/getContentPackList', async () => {
    try {
        const response = await adminDomainServices.adminGetContentPackList();
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const assignContentPackItems = createAsyncThunk('adminDomainInfo/assignContentPackItems', async ({ qprm }) => {
    try {
        const response = await adminDomainServices.adminAssignContentPackItems({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);


export const getDomainVisualData = createAsyncThunk('adminDomainInfo/getDomainVisualData', async ({ qprm }) => {
    try {
        const response = await adminDomainServices.adminGetDomainVisualData({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);
export const addEditPremiumContentDetails = createAsyncThunk('adminDomainInfo/addEditPremiumContentDetails', async ({ qprm }) => {
    try {
        const response = await adminDomainServices.adminAddEditPremiumContentDetails({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
});

export const getDomainInfoListToEdit = createAsyncThunk('adminDomainInfo/getDomainInfoListToEdit', async ({ qprm }) => {
    try {
        const response = await adminDomainServices.adminGetDomainInfoListToEdit({ qprm });
        console.log("response in domain info edit", response);
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
});

export const getDomainInfoList = createAsyncThunk('adminDomainInfo/getDomainInfoList', async ({ qprm }) => {
    try {
        const response = await adminDomainServices.adminGetDomainInfoList({ qprm });
        if (typeof response.data !== "undefined" && response.data.status === 1) {
            let decryptedDt = decryptIt(response.data.data);
            decryptedDt = decryptedDt ? decryptedDt.slice(1, -1) : "";
            decryptedDt = JSON.parse(decryptedDt);
            return decryptedDt;
        }
    } catch (e) {
        return e.response.data;
    }
});
export const getVoucherCodeList = createAsyncThunk('adminDomainInfo/getVoucherCodeList', async ({ qprm }) => {
    try {
        const response = await adminDomainServices.getVoucherCodeData({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
})

export const adminAssignDomainMenuItem = createAsyncThunk('adminDomainInfo/adminAssignDomainMenuItem', async ({ qprm }) => {
    try {
        const response = await adminDomainServices.adminAssignDomainMenuItem({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
})

export const adminGetDomainMenuItem = createAsyncThunk('adminDomainInfo/adminGetDomainMenuItem', async ({ qprm }) => {
    try {
        const response = await adminDomainServices.adminGetDomainMenuItem({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
})

export const adminDomainSlice = createSlice({
    name: 'adminDomainInfo',
    initialState: {
        loading: false,
        error: null,
        data: [],
        submitDistriInfo: false,
        submitting: false,
    },
    reducers: {},
    extraReducers: {
        [adminDistributorList.pending]: (state) => {
            state.loading = true;
        },
        [adminDistributorList.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminDistributorList.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminCreateDistributor.pending]: (state) => {
            state.submitDistriInfo = true;
        },
        [adminCreateDistributor.rejected]: (state, action) => {
            state.submitDistriInfo = false;
        },
        [adminCreateDistributor.fulfilled]: (state, action) => {
            state.submitDistriInfo = false;
            state.data = action.payload.data;
        },
        [adminRemoveDistributor.pending]: (state) => {
            state.loading = true;
        },
        [adminRemoveDistributor.rejected]: (state, action) => {
            state.loading = false;
        },
        [adminRemoveDistributor.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [DomainDistributorList.pending]: (state) => {
            state.loading = true;
        },
        [DomainDistributorList.rejected]: (state, action) => {
            state.loading = false;
        },
        [DomainDistributorList.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [masterSiteList.pending]: (state) => {
            state.loading = true;
        },
        [masterSiteList.rejected]: (state, action) => {
            state.loading = false;
        },
        [masterSiteList.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [creatingNewSite.pending]: (state) => {
            state.loading = true;
        },
        [creatingNewSite.rejected]: (state, action) => {
            state.loading = false;
        },
        [creatingNewSite.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [getDomainInfo.pending]: (state) => {
            state.loading = true;
        },
        [getDomainInfo.rejected]: (state, action) => {
            state.loading = false;
        },
        [getDomainInfo.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [getAllDomainList.pending]: (state) => {
            state.loading = true;
        },
        [getAllDomainList.rejected]: (state) => {
            state.loading = false;
        },
        [getAllDomainList.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [getDomainMediaContentWithPriceList.pending]: (state) => {
            state.loading = true;
        },
        [getDomainMediaContentWithPriceList.rejected]: (state) => {
            state.loading = false;
        },
        [getDomainMediaContentWithPriceList.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [updateCustomPublicationCosts.pending]: (state) => {
            state.submitting = true;
        },
        [updateCustomPublicationCosts.rejected]: (state) => {
            state.submitting = false;
        },
        [updateCustomPublicationCosts.fulfilled]: (state, action) => {
            state.submitting = false;
            state.data = action.payload.data;
        },
        [getDomainMediaContentIdsBasedOnType.pending]: (state) => {
            state.loading = true;
        },
        [getDomainMediaContentIdsBasedOnType.rejected]: (state) => {
            state.loading = false;
        },
        [getDomainMediaContentIdsBasedOnType.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [assignContentIdsToDomain.pending]: (state) => {
            state.submitting = true;
        },
        [assignContentIdsToDomain.rejected]: (state) => {
            state.submitting = false;
        },
        [assignContentIdsToDomain.fulfilled]: (state, action) => {
            state.submitting = false;
            state.data = action.payload.data;
        },
        [getGeoDomainContentPackList.pending]: (state) => {
            state.loading = true;
        },
        [getGeoDomainContentPackList.rejected]: (state) => {
            state.loading = false;
        },
        [getGeoDomainContentPackList.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [getSpecificGeoDomainPackInfo.pending]: (state) => {
            state.loading = true;
        },
        [getSpecificGeoDomainPackInfo.rejected]: (state) => {
            state.loading = false;
        },
        [getSpecificGeoDomainPackInfo.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [getSpecificDomainContentBlocks.pending]: (state) => {
            state.loading = true;
        },
        [getSpecificDomainContentBlocks.rejected]: (state) => {
            state.loading = false;
        },
        [getSpecificDomainContentBlocks.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [updateDomainBlocksOrder.pending]: (state) => {
            state.loading = true;
        },
        [updateDomainBlocksOrder.rejected]: (state) => {
            state.loading = false;
        },
        [updateDomainBlocksOrder.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [assignGeoDomainContents.pending]: (state) => {
            state.loading = true;
        },
        [assignGeoDomainContents.rejected]: (state) => {
            state.loading = false;
        },
        [assignGeoDomainContents.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [getContentPackList.pending]: (state) => {
            state.loading = true;
        },
        [getContentPackList.rejected]: (state) => {
            state.loading = false;
        },
        [getContentPackList.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [assignContentPackItems.pending]: (state) => {
            state.submitting = true;
        },
        [assignContentPackItems.rejected]: (state) => {
            state.submitting = false;
        },
        [assignContentPackItems.fulfilled]: (state, action) => {
            state.submitting = false;
            state.data = action.payload.data;
        },
        [getDomainVisualData.pending]: (state) => {
            state.loading = true;
        },
        [getDomainVisualData.rejected]: (state) => {
            state.loading = false;
        },
        [getDomainVisualData.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [addEditPremiumContentDetails.pending]: (state) => {
            state.loading = true;
        },
        [addEditPremiumContentDetails.rejected]: (state, action) => {
            state.loading = false;
        },
        [addEditPremiumContentDetails.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [getDomainInfoListToEdit.pending]: (state) => {
            state.loading = true;
        },
        [getDomainInfoListToEdit.rejected]: (state, action) => {
            state.loading = false;
        },
        [getDomainInfoListToEdit.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [getDomainInfoList.pending]: (state) => {
            state.loading = true;
        },
        [getDomainInfoList.rejected]: (state, action) => {
            state.loading = false;
        },
        [getDomainInfoList.fulfilled]: (state, action) => {
            state.loading = false;
            state.domainList = action.payload;
        },
        [getVoucherCodeList.pending]: (state) => {
            state.loading = true;
        },
        [getVoucherCodeList.rejected]: (state) => {
            state.loading = false;
        },
        [getVoucherCodeList.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [adminAssignDomainMenuItem.pending]: (state) => {
            state.submitting = true;
        },
        [adminAssignDomainMenuItem.rejected]: (state) => {
            state.submitting = false;
        },
        [adminAssignDomainMenuItem.fulfilled]: (state, action) => {
            state.submitting = false;
            state.data = action.payload.data;
        },
        [adminGetDomainMenuItem.pending]: (state) => {
            state.loading = true;
        },
        [adminGetDomainMenuItem.rejected]: (state) => {
            state.loading = false;
        },
        [adminGetDomainMenuItem.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
    }
})


export default adminDomainSlice.reducer;
