import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import contentOrdersService from '../components/services/contentOrdersService';


export const frontContentOrderGetPublicationCostInfo = createAsyncThunk('frontContentOrdersInfo/frontContentOrderGetPublicationCostInfo',async ({ qprm }) => {
    try { 
        const response = await contentOrdersService.getCustomPublicationCostOfMediaItems({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
); 

export const frontContentOrderGetSalesTax = createAsyncThunk('frontContentOrdersInfo/frontContentOrderGetSalesTax',async ({ qprm }) => {
    try { 
        const response = await contentOrdersService.getStripeSalesTax({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
); 

export const frontContentOrderSubmit = createAsyncThunk('frontContentOrdersInfo/frontContentOrderSubmit',async ({ qprm }) => {
    try { 
        const response = await contentOrdersService.getContentOrderSubmit({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
); 


export const frontContentPayByLink = createAsyncThunk('frontContentOrdersInfo/frontContentPayByLink',async ({ qprm }) => {
    try { 
        const response = await contentOrdersService.getPayByLinkUrl({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const frontCustomerCreateStripe = createAsyncThunk('frontContentOrdersInfo/frontCustomerCreateStripe',async ({ qprm }) => {
    try { 
        const response = await contentOrdersService.frontCustomerCreateStripeUsingPId({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const frontPageTopContentGather = createAsyncThunk('frontContentOrdersInfo/frontPageTopContentGather',async ({ qprm }) => {
    try { 
        const response = await contentOrdersService.frontPageTopContentGather({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const commonNewsLetterDataStoreReducer = createAsyncThunk('frontContentOrdersInfo/commonNewsLetterDataStoreReducer',async ({ qprm }) => {
    try { 
        const response = await contentOrdersService.commonNewsLetterDataStoreReducer({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const frontPlaceOrderForDigitalPublication = createAsyncThunk('frontContentOrdersInfo/frontPlaceOrderForDigitalPublication',async ({ qprm }) => {
    try { 
        const response = await contentOrdersService.frontPlaceOrderForDigitalPublication({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const frontGetMediaCategoriesToListOnTheBasisOfType = createAsyncThunk('frontContentOrdersInfo/frontGetMediaCategoriesToListOnTheBasisOfType',async ({ qprm }) => {
    try { 
        const response = await contentOrdersService.frontGetMediaCategoriesToListOnTheBasisOfType({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const frontGetDomainMediaContents = createAsyncThunk('frontContentOrdersInfo/frontGetDomainMediaContents',async ({ qprm }) => {
    try { 
        const response = await contentOrdersService.frontGetDomainMediaContents({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const frontGetSpecificMediaContentInfo = createAsyncThunk('frontContentOrdersInfo/frontGetSpecificMediaContentInfo',async ({ qprm }) => {
    try { 
        const response = await contentOrdersService.frontGetSpecificMediaContentInfo({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
);

export const frontContentOrdersSlice = createSlice({
    name:'frontContentOrdersInfo',
    initialState: {
        loading: false,
        error: null,
        data: [],
        loadingContent:false
    },
    reducers: {},
    extraReducers: {
        [frontContentOrderGetPublicationCostInfo.pending]: (state) => {
            state.loading = true;
        },
        [frontContentOrderGetPublicationCostInfo.rejected]: (state, action) => {
            state.loading = false;
        },
        [frontContentOrderGetPublicationCostInfo.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [frontContentOrderGetSalesTax.pending]: (state) => {
            state.loading = true;
        },
        [frontContentOrderGetSalesTax.rejected]: (state, action) => {
            state.loading = false;
        },
        [frontContentOrderGetSalesTax.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [frontContentOrderSubmit.pending]: (state) => {
            state.loading = true;
        },
        [frontContentOrderSubmit.rejected]: (state, action) => {
            state.loading = false;
        },
        [frontContentOrderSubmit.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [frontContentPayByLink.pending]: (state) => {
            state.loading = true;
        },
        [frontContentPayByLink.rejected]: (state, action) => {
            state.loading = false;
        },
        [frontContentPayByLink.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [frontCustomerCreateStripe.pending]: (state) => {
            state.loading = true;
        },
        [frontCustomerCreateStripe.rejected]: (state, action) => {
            state.loading = false;
        },
        [frontCustomerCreateStripe.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [frontPageTopContentGather.pending]: (state) => {
            state.loadingContent = true;
        },
        [frontPageTopContentGather.rejected]: (state, action) => {
            state.loadingContent = false;
        },
        [frontPageTopContentGather.fulfilled]: (state, action) => {
            state.loadingContent = false;
            state.data = action.payload.data;
        },
        [commonNewsLetterDataStoreReducer.pending]: (state) => {
            state.loading = true;
        },
        [commonNewsLetterDataStoreReducer.rejected]: (state, action) => {
            state.loading = false;
        },
        [commonNewsLetterDataStoreReducer.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [frontPlaceOrderForDigitalPublication.pending]: (state) => {
            state.loading = true;
        },
        [frontPlaceOrderForDigitalPublication.rejected]: (state, action) => {
            state.loading = false;
        },
        [frontPlaceOrderForDigitalPublication.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [frontGetMediaCategoriesToListOnTheBasisOfType.pending]: (state) => {
            state.loading = true;
        },
        [frontGetMediaCategoriesToListOnTheBasisOfType.rejected]: (state, action) => {
            state.loading = false;
        },
        [frontGetMediaCategoriesToListOnTheBasisOfType.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [frontGetDomainMediaContents.pending]: (state) => {
            state.loading = true;
        },
        [frontGetDomainMediaContents.rejected]: (state, action) => {
            state.loading = false;
        },
        [frontGetDomainMediaContents.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        [frontGetSpecificMediaContentInfo.pending]: (state) => {
            state.loading = true;
        },
        [frontGetSpecificMediaContentInfo.rejected]: (state, action) => {
            state.loading = false;
        },
        [frontGetSpecificMediaContentInfo.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        
    }  
})

export default frontContentOrdersSlice.reducer;