import { apiConstants } from '../../constants/api.constants';
import ApiService from './ApiServices';

export default class adminDomainServices {

  static adminDistributorList = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_DISTRIBUTOR_LIST_FROM_UID, { qprm: qprm });
  };

  static adminCreateDistributor = ({ qprm }) => {
    return ApiService.post(apiConstants.API_CREATE_DISTRIBUTOR_INFO, { qprm: qprm });
  };

  static adminRemoveDistributor = ({ qprm }) => {
    return ApiService.post(apiConstants.API_REMOVE_DISTRIBUTOR_INFO, { qprm: qprm });
  };

  static adminDomainDistributorInfo = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_DOMAIN_DISTRIBUTOR_INFO, { qprm: qprm });
  };

  static adminMasterSiteList = ({ qprm }) => {
    return ApiService.post(apiConstants.GET_MASTER_SITE, { qprm });
  };

  static adminCreateSite = ({ qprm }) => {
    return ApiService.post(apiConstants.CREATE_NEW_SITE, { qprm: qprm });
  };

  static adminGetDomainInfo = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_UID_DOMAIN_INFO, { qprm: qprm });
  };

  static adminGetAllDomainList = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_DOMAIN_LIST, { qprm });
  }

  static adminGetDomainMediaContentWithPriceList = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_DOMAIN_MEDIA_CONTENT_INFO, { qprm });
  }

  static adminUpdateCustomPublicationCosts = ({ qprm }) => {
    return ApiService.post(apiConstants.API_UPDATE_CUSTOM_PUBLICATION_CONTENT_PRICE, { qprm });
  }

  static adminGetDomainMediaContentIdsBasedOnType = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_DOMAIN_CIDS_BASED_ON_TYPE, { qprm });
  }

  static adminAssignContentIdsToDomain = ({ qprm }) => {
    return ApiService.post(apiConstants.ASSIGN_CID_TO_DOMAIN_FOR_SPECIFIC_TYPE, { qprm });
  }

  static adminGetGeoDomainContentPackList = () => {
    return ApiService.post(apiConstants.API_TO_LIST_GEO_LOCATION_CONTENT_PACK_LIST, {});
  }

  static adminGetSpecificGeoDomainPackInfo = ({ qprm }) => {
    return ApiService.post(apiConstants.API_TO_GET_SPECIFIC_EXISTSING_CONTENT_PACK_INFO, { qprm });
  }

  static adminDomainSpecificContentBlocks = ({ qprm }) => {
    return ApiService.post(apiConstants.GET_SPECIFIC_DOMAIN_CONTENT_BLOCKS, { qprm });
  };

  static adminUpdateDomainBlocksOrder = ({ qprm }) => {
    return ApiService.post(apiConstants.UPDATE_DOMAIN_BLOCKS_ORDER, { qprm });
  };

  static adminAssignGeoDomainContents = ({ qprm }) => {
    return ApiService.upload(apiConstants.API_TO_SAVE_AND_ASSIGN_GEO_DOMAIN_CONTENTS, { qprm });
  }

  static adminGetContentPackList = () => {
    return ApiService.post(apiConstants.API_TO_LIST_CONTENT_PACK_LIST, {});
  }

  static adminAssignContentPackItems = ({ qprm }) => {
    return ApiService.post(apiConstants.API_TO_SAVE_AND_ASSIGN_CONTENT_PACK_ITEMS, { qprm });
  }

  static adminGetDomainVisualData = ({ qprm }) => {
    return ApiService.post(apiConstants.API_TO_GET_VI_DATA, { qprm });
  }

  static adminAddEditPremiumContentDetails = ({ qprm }) => {
    return ApiService.post(apiConstants.API_TO_ADD_EDIT_PREMIUM_CONTENT_DETAILS, { qprm });
  }
  static adminGetDomainInfoList = ({ qprm }) => {
    return ApiService.post(apiConstants.API_TO_GET_DOMAIN_INFO_LIST, { qprm });
  }

  static adminGetDomainInfoListToEdit = ({ qprm }) => {
    return ApiService.post(apiConstants.API_TO_GET_DOMAIN_INFO_LIST_TO_EDIT, { qprm });
  }

  static getVoucherCodeData = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_VOUCHER_LIST, { qprm });
  }

  static adminAssignDomainMenuItem = ({ qprm }) => {
    return ApiService.post(apiConstants.API_ASSIGN_DOMAIN_MENU, { qprm })
  }
  static adminGetDomainMenuItem = ({ qprm }) => {
    return ApiService.post(apiConstants.API_GET_DOMAIN_MENUS, { qprm })
  }
}


