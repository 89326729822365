import { apiConstants } from "../../constants/api.constants";
import ApiService from "./ApiServices";

const add = `/`;

export default class tagServices {

    static getTagData = ({ qprm }) => {
        return ApiService.post(apiConstants.API_GET_TAG_DATA, { qprm: qprm });
    };

    static createTagData = ({ qprm }) => {
        return ApiService.post(apiConstants.CREATE_TAG, { qprm: qprm });
    };

    static deleteTagData = ({ qprm }) => {
        return ApiService.post(apiConstants.DELETE_TAG, { qprm: qprm });
    };

    static getTagDataById = ({ qprm }) => {
        return ApiService.post(apiConstants.API_GET_TAG_DATA + add + qprm);
    };

    static editTagData = ({ data, qprm }) => {
        return ApiService.post(apiConstants.EDIT_TAG + add + data, { qprm: qprm });
    };

}
