import { apiConstants } from '../../constants/api.constants';
import ApiService from './ApiServices';

export default class adminPaymentServices {

    static adminCreateDiscountCouponCode = ({ qprm }) => {
        return ApiService.post(apiConstants.API_CREATE_COUPON_CODE, { qprm: qprm });
    };

    static adminGetOneDiscountCouponCode = ( {qprm}) => {
        return ApiService.post(apiConstants.API_GET_DISCOUNT_COUPON_CODE , {qprm: qprm});
    };

    static adminCheckUniquenessOfDiscountCode = ( {qprm}) => {
        return ApiService.post(apiConstants.API_CHECK_UNIQUENESS_COUPON_CODE , {qprm: qprm});
    };
    
    static adminDeleteDiscountCouponCode = ( {qprm}) => {
        return ApiService.post(apiConstants.API_DELETE_DISCOUNT_COUPON_CODE , {qprm: qprm});
    };

    static adminGetUserMediaContentAccessList = ({ qprm } ) =>{
        return ApiService.post(apiConstants.API_GET_USER_MEDIA_CONTENT_ACCESS_LIST, { });
    };
}