import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import feedbackServices from '../components/services/feedbackServices';

export const frontHotelSaveFeedback = createAsyncThunk('frontFeedbackInfo/frontHotelSaveFeedback',async ({ qprm }) => {
    try { 
        const response = await feedbackServices.hotelSaveFeedback({ qprm });
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
            const data = await response.data;
            return data;
        }
    } catch (e) {
        return e.response.data;
    }
}
); 

export const frontFeedbackSlice = createSlice({
    name:'frontFeedbackInfo',
    initialState: {
        loading: false,
        error: null,
        data: [],
    },
    reducers: {},
    extraReducers: {
        [frontHotelSaveFeedback.pending]: (state) => {
            state.loading = true;
        },
        [frontHotelSaveFeedback.rejected]: (state, action) => {
            state.loading = false;
        },
        [frontHotelSaveFeedback.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
    }
})


export default frontFeedbackSlice.reducer;
