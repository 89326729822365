export const PUBLIC_URL = process.env.PUBLIC_URL;
export const BASE_URL = window.location.origin;
export const CLOUDFLARE_IMAGE_RESIZING = (process.env.REACT_APP_ENV !== 'undefined' && process.env.REACT_APP_ENV !== 'local') ? true : false;
export const ASSETS_URL = BASE_URL + '/assets/';
export const PUBLIC_IMAGES_URL = !CLOUDFLARE_IMAGE_RESIZING ? ASSETS_URL + 'img/' : '/cdn-cgi/image/q=50,f=auto,onerror=redirect/' + ASSETS_URL + 'img/';
export const PUBLIC_LIBRARIES_URL = ASSETS_URL + 'libraries/';
export const PUBLIC_CSS_URL = ASSETS_URL + 'css/';
export const PUBLIC_JS_URL = ASSETS_URL + 'js/';
export const MANUAL_CONTENT_URL = ASSETS_URL + 'uploads';
export const AUTOMATED_CONTENT_URL = ASSETS_URL + 'uploads/media-content/automated/';
export const VI_IMAGES_URL = !CLOUDFLARE_IMAGE_RESIZING ? ASSETS_URL + 'uploads/visual_data' : '/cdn-cgi/image/q=90,f=auto,onerror=redirect/' + ASSETS_URL + 'uploads/visual_data';
export const API_URL = process.env.REACT_APP_API_URL;
export const MEDIA_CONTENT_TYPES = ['newspaper', 'magazine', 'games', 'video', 'audio', 'shortstoryaudio', 'blinkist', 'ebook', 'custom', 'featured', 'books', 'mobi', 'kobo', 'shortstoryebook', 'featured2', 'featured3', 'featured4', 'comics', 'gallery', "todaysnewspaper", "editorspick"];
export const CHILDREN_CAT_ID = '5fa54068e530f0718d31a23e';
export const MEDIA_BLOCK_TYPE_LINK = {
  'media_content_listing-featured': 'featured',
  'media_content_listing-newspaper': 'newspaper',
  'media_content_listing-magazine': 'magazine',
  'media_content_listing-games': 'games',
  'media_content_listing-ebooks': 'ebook',
  'media_content_listing-kobo': 'kobo',
  'media_content_listing-kindle': 'mobi',
  'media_content_listing-videos': 'video',
  'media_content_listing-audio': 'audio',
  'media_content_listing-block_3': 'children',
  'media_content_listing-block_2': 'shortstoryebook',
  'media_content_listing-podcasts': 'shortstoryaudio',
  'media_content_listing-blinkist': 'blinkist',
  '4fa46424dd828052be9a2d0d7d392a08': 'custom',
  'media_content_listing-comics': 'comics',
  'aa6e9480178d026da31a553e335a9414': 'featured2',
  'b58a369036dd0ee079b41018ea3fdd28': 'featured3',
  'abaa6abe65651ec44891728b16db16e6': 'featured4',
  'media_content_listing-book': 'books',
  "d561124aa39cac2ffd01a94b9543c855": 'compendium',
  "media_content_listing-gallery": 'gallery',
  "38bda789d1e648b767f894dd1e6d0d06": 'todaysnewspaper',
  "37c89c8fa7c7879863cc168062cc953d": 'editorspick'
};
export const SINGLE_BLOCKS = ['vi_first_slider', 'flight_info', 'vi_second_slider', 'vi_third_slider', 'vi_fourth_slider', 'vi_first_ad_flex', 'vi_second_ad_flex', 'type_single_block-block', '4fa46424dd828052be9a2d0d7d392a08', 'social_media', 'custom_html_block_one', 'get_your_guide', 'top-media-items-block', 'news-letter-signup-block', 'faq-block', 'testimonial_block'];
export const LOADER_IMG = 'bg-imgbook.png';
export const UNLOADER_IMG = 'missing.jpg';
export const POWERED_BY_LOGO_IMG = 'MP_powered_by_2020.svg';
export const FOOTER_LOGO = 'footer-logo.svg';
export const WEBVIEWER_LOGO = PUBLIC_LIBRARIES_URL + 'webviewer/lib/external/mediapad_logo.svg';
export const MEDIA_CONTENT_URL = '/media/content/';
export const TYPE_OPTIONS = [
  { label: "Newspaper", value: "newspaper" },
  { label: "Magazine", value: "magazine" },
  { label: "Games", value: "games" },
  { label: "Ebooks", value: "ebook" },
  { label: "Audio", value: "audio" },
  { label: "Video", value: "video" },
  { label: "Podcasts", value: "shortstoryaudio" },
  { label: "Children", value: "children" },
  { label: "Books", value: "books" },
  { label: "Curiosity Stream", value: "shortstoryebook" },
  { label: "Blinkist", value: "blinkist" },
  { label: "Featured", value: "featured" },
  { label: "Kobo", value: "kobo" },
  { label: "Kindle", value: "mobi" },
  { label: "Custom", value: "custom" },
  { label: "Featured 2", value: "featured2" },
  { label: "Featured 3", value: "featured3" },
  { label: "Featured 4", value: "featured4" },
  { label: "Comics", value: "comics" },
  { label: "Gallery", value: "gallery" },

];
export const SOUNDCLOUD_URL = 'https://w.soundcloud.com/player/?auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&visual=true&url=';
export const IFRAME_CONTENT_ARR = ['audio', 'video', 'shortstoryaudio'];
export const RTL_LANGUAGES = ['ar', 'ur', 'he'];
export const USE_DEFAULT_DOMAIN = false;
export const loungeEnterOptions = [
  { id: 1, label: 'Priority Pass' },
  { id: 2, label: 'Lounge Key' },
  { id: 3, label: 'Lounge Pass' },
  { id: 4, label: 'Lounge Club' },
  { id: 5, label: 'Dinners Club' },
  { id: 6, label: 'Mastercard Airport Experience' },
  { id: 7, label: 'Dragon Pass' },
  { id: 8, label: 'Class of Travel' },
  { id: 9, label: 'Paid on the Door' },
  { id: 10, label: 'Other' },
];
export const POOL_DATA = {
  UserPoolId: process.env.REACT_APP_AWS_USERPOOL_ID,
  ClientId: process.env.REACT_APP_AWS_CLIENT_ID,
};
export const TIME_KIT_COMMON_API_URL = `https://api.timekit.io/v2/bookings?orderBy=event.start&sortedBy=desc&and=true&include=attributes`;
export const csvStaffPortalHeaders = [
  { label: "Booking Ref", key: "bookingRef" },
  { label: "Amenity", key: "amenity" },
  { label: "Time", key: "time" },
  { label: "Email", key: "email" },
  { label: "Name", key: "name" },
  { label: "Check In Time", key: "checkInTime" },
  { label: "Status", key: "status" }
];
export const COUNTRIES = [
  { value: 'AT', label: 'Austria' },
  { value: 'BR', label: 'Brazil' },
  { value: 'CN', label: 'China' },
  { value: 'DK', label: 'Denmark' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'DE', label: 'Germany' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IT', label: 'Italy' },
  { value: 'JP', label: 'Japan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'MX', label: 'Mexico' },
  { value: 'MC', label: 'Monaco' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NO', label: 'Norway' },
  { value: 'OM', label: 'Oman' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'RO', label: 'Romania' },
  { value: 'RU', label: 'Russia' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SG', label: 'Singapore' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'KR', label: 'South Korea' },
  { value: 'SE', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TR', label: 'Turkey' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'US', label: 'United States' },
  { value: 'VN', label: 'Vietnam' }
];

export const findDateDiff = (anotherDate) => {
  const today = new Date();
  const otherDateStr = anotherDate;
  const otherDate = new Date(otherDateStr);

  const timeDifference = today.getTime() - otherDate.getTime();
  const millisecondsInDay = 1000 * 60 * 60 * 24;
  const daysDifference = Math.floor(timeDifference / millisecondsInDay);

  const yearsDifference = Math.floor(daysDifference / 365);
  const remainingDays = daysDifference % 365;
  const months = Math.floor(remainingDays / 30);
  const remainingDaysInMonth = remainingDays % 30;

  let diff = '';

  if (yearsDifference > 0) {
    diff += `${yearsDifference} year${yearsDifference > 1 ? 's' : ''}`;
  }

  if (months > 0) {
    if (diff) {
      diff += ' and ';
    }
    diff += `${months} month${months > 1 ? 's' : ''}`;
  }

  if (remainingDaysInMonth > 0) {
    if (diff) {
      diff += ' and ';
    }
    diff += `${remainingDaysInMonth} day${remainingDaysInMonth > 1 ? 's' : ''}`;
  }

  return diff;
};

export const findTimeDifference = (timestamp) => {
  const givenTime = new Date(timestamp);

  const currentTime = new Date();

  const timeDifference = currentTime - givenTime;

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  return `${days < 1 ? "" : `${days}${days == 1 ? "day" : "days"}`}
    ${hours < 1 ? "" : `${hours} ${hours == 1 ? "hour" : "hours"}`}
    ${minutes}min
    ${seconds}sec ago`;
};

export const rolesPermissions = [
  {
    label: "Custom Configuration Pages",
    value: "customConfigurationPages",
  },

  {
    label: "Administer users",
    value: "administerUsers",
  },

  {
    label: "Administer permissions",
    value: "administerPermissions",
  },

  {
    label: "View user profiles",
    value: "viewUserProfiles",
  },

  {
    label: "Change own username",
    value: "changeOwnUsername",
  },

  {
    label: "Cancel own user account",
    value: "cancelOwnUserAccount",
  },

  {
    label: "Select method for cancelling own account",
    value: "selectMethodForCancellingOwnAccount",
  },

  {
    label: "Translate user-defined strings",
    value: "translateUserDefinedStrings",
  },

  {
    label: "Translate admin strings",
    value: "translateAdminStrings",
  },

  {
    label: "Access Reporting Informations",
    value: "accessReportingInformations",
  },

  {
    label: "Access Auditor Report",
    value: "accessAuditorReport",
  },

  {
    label: "Access Summary Report",
    value: "accessSummaryReport",
  },

  {
    label: "Access Subscription Report Dashboard",
    value: "accessSubscriptionReportDashboard",
  },

  {
    label: "Access analytic reports",
    value: "accessAnalyticReports",
  },

  {
    label: "Access publication report",
    value: "accessPublicationReport",
  },

  {
    label: "Access Auditor analytics",
    value: "accessAuditorAnalytics",
  },

  {
    label: "Access Report domain list",
    value: "accessReportDomainList",
  },

  {
    label: "Report Sheet Content Import",
    value: "reportSheetContentImport",
  },

  {
    label: "Manage Report Statement Setting",
    value: "manageReportStatementSetting",
  },

  {
    label: "Manage Report Statement Log History",
    value: "manageReportStatementLogHistory",
  },

  {
    label: "Administer MMS Content",
    value: "administerMMSContent",
  },

  {
    label: "Access File Status Permission",
    value: "accessFileStatusPermission",
  },

  {
    label: "Check File Status Permission",
    value: "checkFileStatusPermission",
  },

  {
    label: "Access domain settings form",
    value: "accessDomainSettingsFrom",
  },

  {
    label: " Access Voucher Code Permission",
    value: "accessVoucherCodePermission",
  },

  {
    label: " Custom Admin Configuration Pages",
    value: "customAdminConfigurationPages",
  },

  {
    label: "Manage Compendium Content",
    value: "manageCompendiumContent",
  },

  {
    label: "List Compendium Content",
    value: "listCompendiumContent",
  },

  {
    label: "Order Compendium Content",
    value: "orderCompendiumContent",
  },

  {
    label: " View GeoLocation Tracking",
    value: "viewGeoLocationTracking",
  },

  {
    label: "Manage Collinson Internal Page Settings",
    value: "manageCollinsonInternalpageSettings",
  },

  {
    label: "Manage Collinson Staff Portal Settings",
    value: "manageCollinsonStaffPortalSettings",
  },
];

export const FONT_STYLE = [
  { value: "'Open Sans', sans-serif", label: "'Open Sans', sans-serif" },
  { value: "'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif", label: "'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif" },
  { value: 'Helvetica Neue', label: 'Helvetica Neue' },
  { value: 'sans-serif', label: 'sans-serif' },
  { value: 'Baskerville, BaskervilleOldFace, serif', label: 'Baskerville, BaskervilleOldFace, serif' },
  { value: 'Baskerville', label: 'Baskerville' },
  { value: 'BaskervilleOldFace', label: 'BaskervilleOldFace' },
  { value: 'Arial', label: 'Arial' },
  { value: 'Verdana', label: 'Verdana' },
  { value: 'Verdana-Bold', label: 'Verdana-Bold' },
  { value: 'Verdana-BoldItalic', label: 'Verdana-BoldItalic' },
  { value: 'Verdana-Italic', label: 'Verdana-Italic' },
  { value: 'ChaletNewYorkSixty', label: 'ChaletNewYorkSixty' },
  { value: 'ChaletLondonSixty', label: 'ChaletLondonSixty' },
  { value: 'TFArrow', label: 'TFArrow' },
  { value: 'TFArrowLight', label: 'TFArrowLight' },
  { value: 'Dubai Font Bold', label: 'Dubai Font Bold' },
  { value: 'Dubai Font Light', label: 'Dubai Font Light' },
  { value: 'Dubai Font Medium', label: 'Dubai Font Medium' },
  { value: 'Dubai Font Regular', label: 'Dubai Font Regular' },
  { value: 'Johnson Bold', label: 'Johnson Bold' },
  { value: 'Johnson Bold Italic', label: 'Johnson Bold Italic' },
  { value: 'Johnson Light', label: 'Johnson Light' },
  { value: 'Johnson Light Italic', label: 'Johnson Light Italic' },
  { value: 'Johnson Medium', label: 'Johnson Medium' },
  { value: 'Johnson Medium Italic', label: 'Johnson Medium Italic' },
  { value: 'Goldenbook', label: 'Goldenbook' },
  { value: 'Goldenbook Bold', label: 'Goldenbook Bold' },
  { value: 'Goldenbook Light', label: 'Goldenbook Light' },
  { value: 'LTPro Regular', label: 'LTPro Regular' },
  { value: 'Zurich Bold', label: 'Zurich Bold' },
  { value: 'Zurich Bold Italic', label: 'Zurich Bold Italic' },
  { value: 'Zurich Italic', label: 'Zurich Italic' },
  { value: 'Zurich Light', label: 'Zurich Light' },
  { value: 'Zurich Light Italic', label: 'Zurich Light Italic' },
  { value: 'Zurich Medium', label: 'Zurich Medium' },
  { value: 'Zurich Medium Italic', label: 'Zurich Medium Italic' },
  { value: 'Zurich Regular', label: 'Zurich Regular' },
  { value: 'Zurich Semi Bold', label: 'Zurich Semi Bold' },
  { value: 'Zurich Semi Bold Italic', label: 'Zurich Semi Bold Italic' },
  { value: 'requiem', label: 'requiem' },
  { value: 'Barlow Black', label: 'Barlow Black' },
  { value: 'Barlow Bold', label: 'Barlow Bold' },
  { value: 'Barlow Light', label: 'Barlow Light' },
  { value: 'Barlow Regular', label: 'Barlow Regular' },
  { value: 'Euclid Square', label: 'Euclid Square' },
  { value: 'Antipasto Pro', label: 'Antipasto Pro' },
  { value: 'Montserrat', label: 'Montserrat' },
  { value: 'Century Old Style', label: 'Century Old Style' },
  { value: 'Libel Suit', label: 'Libel Suit' },
  { value: 'Transat Text', label: 'Transat Text' }
];

export const BLOCKS =
  [
    {
      id: 0,
      bg: "Secondry Navigation Block"
    },
    {
      id: 1,
      bg: "Main Banner"
    },
    {
      id: 2,
      bg: "Fourth Banner"
    },
    {
      id: 3,
      bg: "Get your Guide"
    },
    {
      id: 4,
      bg: "First Ad-flex"
    },
    {
      id: 5,
      bg: "Index Block"
    },
    {
      id: 6,
      bg: "Newspapers"
    },
    {
      id: 7,
      bg: "Magazines"
    },
    {
      id: 8,
      bg: "Games"
    },
    {
      id: 9,
      bg: "Audio"
    },
    {
      id: 10,
      bg: "Video"
    },
    {
      id: 11,
      bg: "Podcasts"
    },
    {
      id: 12,
      bg: "Ebooks"
    },
    {
      id: 13,
      bg: "Kindle"
    },
    {
      id: 14,
      bg: "Comics"
    },
    {
      id: 15,
      bg: "Children"
    },
    {
      id: 16,
      bg: "Short Stories"
    },
    {
      id: 17,
      bg: "Second Banner"
    },
    {
      id: 18,
      bg: "Compendium"
    },
    {
      id: 19,
      bg: "Third Banner"
    },
    {
      id: 20,
      bg: "Second Ad-flex"
    },
    {
      id: 21,
      bg: "Social Media Link Block"
    },
    {
      id: 22,
      bg: "Featured"
    },
    {
      id: 23,
      bg: "Featured 2"
    },
    {
      id: 24,
      bg: "Featured 3"
    },
    {
      id: 25,
      bg: "Featured 4"
    },
    {
      id: 26,
      bg: "Blinkist"
    },
    {
      id: 27,
      bg: "Cutom Issues"
    },
    {
      id: 28,
      bg: "Second Slider"
    },
    {
      id: 29,
      bg: "Second Ad - Flex"
    },
    {
      id: 30,
      bg: "Book Listing"
    },
    {
      id: 31,
      bg: "Third Slider"
    },
    {
      id: 32,
      bg: "First Ad - Flex"
    },
    {
      id: 33,
      bg: "Fourth Slider"
    }
  ];
export const geolocationCPListingCustomStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: "transparent",
    border: "1px solid rgba(255, 255, 255, 0.32)",
    borderRadius: "4px",
    padding: "6px 15px",
    width: '523px',
    outline: "0",
    color: "#7688c9",
    fontSize: "16px",
    lineHeight: "20px",
    boxShadow: "none",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  singleValue: (style) => ({
    ...style,
    color: "#7688c9",
  }),
  option: (styles, { data, isFocused, isSelected }) => {
    return {
      ...styles,
      width: '523px',
      color: "white",
      backgroundColor: "#15161a",
      backgroundColor: isFocused ? "rgba(56, 85, 179, 0.12)" : "#15161a",
    };
  },
  menu: () => ({
    border: "1px solid rgba(255, 255, 255, 0.32)",
    margin: "3px",
    width: '523px',
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      display: "none",
    };
  },
  input: base => ({
    ...base,
    color: "#fff"
  })
};

export const statesList =
{
  "AL": "Alabama",
  "AK": "Alaska",
  "AS": "American Samoa",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "DC": "District Of Columbia",
  "FM": "Federated States Of Micronesia",
  "FL": "Florida",
  "GA": "Georgia",
  "GU": "Guam",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MH": "Marshall Islands",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "MP": "Northern Mariana Islands",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PW": "Palau",
  "PA": "Pennsylvania",
  "PR": "Puerto Rico",
  "RI": "Rhode Island",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VI": "Virgin Islands",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming"
};

export const expiryMonthList = [
  "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"
]


export const expiyYearList = [
  "2023",
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
  "2031",
  "2032",
  "2033",
  "2034",
  "2035",
  "2036",
  "2037",
  "2038",
  "2039",
  "2040",
  "2041",
  "2042",
  "2043"
]

export const MEDIA_CONTENT_TYPE = [
  { label: "Magazine", value: "magazines" },
  { label: "Newspaper", value: "newspapers" },
  { label: "Games", value: "games" },
  { label: "Audio", value: "audio" },
  { label: "Video", value: "video" },
  { label: "Ebooks", value: "ebooks" },
  { label: "Podcasts", value: "podcasts" },
  { label: "Curiosity Stream", value: "curiosity stream" },
  { label: "Children", value: "children" },
  { label: "Featured", value: "featured" },
  { label: "Blinkist", value: "blinkist" },
  { label: "compendium", value: "compendium" },
  { label: "Featured 2", value: "featured 2" },
  { label: "Featured 3", value: "featured 3" },
  { label: "Featured 4", value: "featured 4" },
  { label: "Comics", value: "comics" },
  { label: "Gallery", value: "gallery" },
  { label: "Today's Newspaper", value: "today's newspaper" },
  { label: "Editor's Pick", value: "editor's pick" }
];

export const URL_TYPE = ["games", "ebook", "custom", "books", "kobo", "mobi", "featured2", "featured3", "featured4", "featured"];
