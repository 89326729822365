import { createStore, applyMiddleware } from 'redux';
import reduxThunk from "redux-thunk";
import reduxMulti from 'redux-multi';
import { batchedSubscribe } from 'redux-batched-subscribe';
import reducer from '../reducer/index';

// Add middleware to allow our action creators to return functions and arrays
const createStoreWithMiddleware = applyMiddleware(
  reduxThunk,
  reduxMulti,
)(createStore)

// Ensure our listeners are only called once, even when one of the above
// middleware call the underlying store's `dispatch` multiple times
const createStoreWithBatching = batchedSubscribe(
  fn => fn()
)(createStoreWithMiddleware)

const store = createStoreWithBatching(reducer); 
//const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
export default store;