import React, {Component} from "react";

class Loader extends Component {
	render() {
		const{id,classes} = this.props;
		return(
			<>
				<div id={id} className={'filter-menu-loading ' + classes}>
					<div className="loader-show">
						<div className="loader">
							<svg className="circular" viewBox="25 25 50 50">
								<circle className="path1" cx="50" cy="50" r="20" fill="none" stroke="#fff" strokeWidth="2"></circle>
								<circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"></circle>
							</svg>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Loader;